<template>
  <div
    v-if="isShow"
    class="goods-negative-info"
    :style="styles"
  >
    <!-- 不参与积分活动提示 -->
    <div
      v-if="notPointsText"
      class="item"
      :style="notPointsStyles"
    >
      <Icon
        class="icon"
        is-responsive-name
        :name="notPointsIcon"
        :size="notPointsIconSize"
      />
      <span class="text">
        {{ notPointsText }}
      </span>
    </div>
    <!-- 不可用券提示 -->
    <div
      v-if="notCouponText"
      class="item"
      :style="notCouponStyles"
    >
      <Icon
        class="icon"
        is-responsive-name
        :name="notCouponIcon"
        :size="notCouponIconSize"
      />
      <span class="text">
        {{ notCouponText }}
      </span>
    </div>
    <!-- 不可退提示 -->
    <div
      v-if="notReturnText"
      class="item"
      :style="notReturnStyles"
    >
      <Icon
        class="icon"
        is-responsive-name
        :name="notReturnIcon"
        :size="notReturnIconSize"
      />
      <span class="text">
        {{ notReturnText }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

/**
 * mini车商品负向信息组件
 */

// props
const props = defineProps({
  notPointsText: {
    type: String,
    default: '',
  },
  notPointsTextSize: {
    type: String,
    default: '12px',
  },
  notPointsTextColor: {
    type: String,
    default: '#666',
  },
  notPointsIcon: {
    type: String,
    default: 'sui_icon_info_12px_1',
  },
  notPointsIconSize: {
    type: String,
    default: '12px',
  },
  notPointsIconColor: {
    type: String,
    default: '#959595',
  },

  notCouponText: {
    type: String,
    default: '',
  },
  notCouponTextSize: {
    type: String,
    default: '12px',
  },
  notCouponTextColor: {
    type: String,
    default: '#666',
  },
  notCouponIcon: {
    type: String,
    default: 'sui_icon_info_12px_1',
  },
  notCouponIconSize: {
    type: String,
    default: '12px',
  },
  notCouponIconColor: {
    type: String,
    default: '#959595',
  },

  notReturnText: {
    type: String,
    default: '',
  },
  notReturnTextSize: {
    type: String,
    default: '12px',
  },
  notReturnTextColor: {
    type: String,
    default: '#666',
  },
  notReturnIcon: {
    type: String,
    default: 'sui_icon_info_12px_1',
  },
  notReturnIconSize: {
    type: String,
    default: '12px',
  },
  notReturnIconColor: {
    type: String,
    default: '#959595',
  },

  maxWidth: {
    type: String,
    default: '100%',
  },
})

// computed
const isShow = computed(() => {
  return props.notPointsText || props.notCouponText || props.notReturnText
})

const styles = computed(() => {
  return {
    '--max-width': props.maxWidth,
  }
})

const notPointsStyles = computed(() => {
  return {
    '--text-size': props.notPointsTextSize,
    '--text-color': props.notPointsTextColor,
    '--icon-size': props.notPointsIconSize,
    '--icon-color': props.notPointsIconColor,
  }
})
const notCouponStyles = computed(() => {
  return {
    '--text-size': props.notCouponTextSize,
    '--text-color': props.notCouponTextColor,
    '--icon-size': props.notCouponIconSize,
    '--icon-color': props.notCouponIconColor,
  }
})
const notReturnStyles = computed(() => {
  return {
    '--text-size': props.notReturnTextSize,
    '--text-color': props.notReturnTextColor,
    '--icon-size': props.notReturnIconSize,
    '--icon-color': props.notReturnIconColor,
  }
})
</script>

<style lang="less" scoped>
.goods-negative-info {
  color: #666;
  .item {
    display: flex;
    align-items: center;
    max-width: var(--max-width);
    .icon {
      margin-right: 5px;
      width: var(--icon-size);
      height: var(--icon-size);
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--icon-color) !important;
    }
    .text {
      font-size: var(--text-size);
      color: var(--text-color);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
