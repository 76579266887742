import GoodsNegativeInfo from './GoodsNegativeInfo.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsNegativeInfo = createHelper('GoodsNegativeInfo', getProps)

export {
  GoodsNegativeInfo,
  useGoodsNegativeInfo,
}
