import AddMore from './AddMore.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useAddMore = createHelper('AddMore', getProps)

export {
  AddMore,
  useAddMore,
}
