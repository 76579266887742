import { reactive, readonly, computed, inject } from 'vue'
import { debuggerLog } from '../helpers'
const STORE_KEY = Symbol('store')

export default function defineStore (app) {

  const getDefaultStore = (mergeInfo = {}) => ({
    priceSummary: {},
    goodsInfo: {},
    paymentMethods: [],
    currencySupportList: [],
    payParams: {},
    abtInfo: {},
    language: {},
    defaultPaymentInfo: {},
    selectPaymentInfo: {},
    prePaymentDefaultCurrency: '',
    preSelectPaymentInfo: {},
    visibleGlobalLoading: false,
    preCalculateMallInfo: {}, // 切换支付方式时，如果需要切换币种时则调用的计价接口数据
    calculateMallInfo: {}, // 计价接口原始返回数据
    cccTaxIncludedTips: '', // ccc包税提示
    applePayConfig: {},
    ...mergeInfo
  })

  const state = reactive(getDefaultStore())
  /**
   * 合并state
   *
   * @param {*} payload
   */
  function assignState (payload) {
    debuggerLog('assignState---', payload)
    Object.assign(state, payload)
  }

  function updatePaymentMethods (list) {
    state.paymentMethods = list
  }
  /**
   * 更新loading状态
   *
   * @param {*} { loading = false }
   */
  function updateLoadingStatus ({ loading = false }) {
    state.visibleGlobalLoading = !!loading
  }

  function updateLanguage (language) {
    state.language = Object.assign({}, state.language, language)
  }

  function resetStore () {
    Object.assign(state, getDefaultStore({
      language: state.language,
      visibleGlobalLoading: state.visibleGlobalLoading
    }))
  }

  const sortedPayments = computed(() => state.paymentMethods)

  app.provide(STORE_KEY, readonly({
    state,
    sortedPayments,
    assignState,
    updateLoadingStatus,
    updatePaymentMethods,
    updateLanguage,
    resetStore
  }))
}


export function useStore () {
  return inject(STORE_KEY)
}
