<script setup>
import { onMounted, ref, toRef } from 'vue'
import CancelOrderDrawer from './CancelOrderDrawer.vue'
import ImageStack from './ImageStack.vue'
import { useCancelOrderTip } from './utils/useCancelOrderTip'
import { clickCancelledTipViewReport, exposeCancelledTipReport } from './utils/analysis.js'
import { delCache, CANCEL_ORDER_CACHE_KEY } from './utils/cache'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  }
})

const language = toRef(props, 'language')

const {
  data,
  popupInfo,
  isShow,
  isShowIncentive,
  goodsImg,
  goodsNum,
  btnText,
  titleText,
  incentiveIcon,
  incentiveText,
  isPlatform,
  getData,
  asyncUpdateCartInfo,
  toggleShow,
} = useCancelOrderTip({ language })

// data
const refDrawer = ref(null)
const isOpen = ref(false)

// methods
const handleToggleDrawer = () => {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    clickCancelledTipViewReport()
  }
}
const handleAddCartSuccess = async (cartInfo) => {
  asyncUpdateCartInfo(cartInfo)
  handleToggleDrawer()
  toggleShow()
}
const handleRefresh = async () => {
  refDrawer.value.showLoading?.()
  delCache(data.value.billno, CANCEL_ORDER_CACHE_KEY)
  await getData()
  refDrawer.value.hideLoading?.()
}
const handleAddMoreSuccess = async (cartInfo) => {
  await handleRefresh()
  asyncUpdateCartInfo(cartInfo)
}
onMounted(getData)
</script>

<template>
  <div>
    <div
      v-if="isShow"
      v-expose="exposeCancelledTipReport()"
      class="summary-cancel-order"
      @click="handleToggleDrawer"
    >
      <div class="summary-cancel-order__left">
        <ImageStack
          :img-url="goodsImg"
          :count="goodsNum"
        />
      </div>
      <div class="summary-cancel-order__right">
        <div class="summary-cancel-order__tip">
          <p class="summary-cancel-order__title">
            {{ titleText }}
          </p>
          <div
            v-if="isShowIncentive"
            class="summary-cancel-order__incentive"
          >
            <img :src="incentiveIcon" />
            <p class="text">
              {{ incentiveText }}
            </p>
          </div>
        </div>
        <div class="summary-cancel-order__btn">
          <span class="text">{{ btnText }}</span>
        </div>
      </div>
    </div>
    <CancelOrderDrawer
      ref="refDrawer"
      v-model:visible="isOpen"
      :order-id="data.billno"
      :data="popupInfo"
      :language="language"
      :is-platform="isPlatform"
      @add-cart-success="handleAddCartSuccess"
      @add-more-success="handleAddMoreSuccess"
      @refresh-data="handleRefresh"
    />
  </div>
</template>

<style lang="less" scoped>
.summary-cancel-order {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  margin-top: 10px;
  border: 1px solid rgba(250, 99, 56, 0.30);
  background: var(---sui_color_promo_bg, #FFF6F3);
  cursor: pointer;
  &__right {
    display: flex;
    align-items: center;
    margin-left: 12px;
    flex: 1;
    overflow: hidden;
  }
  &__tip {
    flex: 1;
    overflow: hidden;
  }
  &__title {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__incentive {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 2px;
    height: 14px;
    color: #FA6338;
    img {
      width: 12px;
      height: 12px;
    }
    .text {
      margin-left: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    max-width: 66px;
    height: 24px;
    height: 24px;
    padding: 0 8px;
    border-radius: 28px;
    background: #FA6338;
    cursor: pointer;
    margin-left: 10px;
    .text {
      font-size: 12px;
      font-weight: 590;
      color: @sui_color_white;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
