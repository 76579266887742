<template>
  <div
    v-if="isShow"
    :class="classes"
    @click="handleClick"
  >
    <span
      v-show="!isLoading"
      class="add-more__text"
    >
      {{ text }}
    </span>
    <BallLoading
      v-if="isLoading"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import BallLoading from '../../components/BallLoading.vue'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['click'])

// hooks
const props = defineProps({
  /**
   * 是否展示
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否加载中
   */
  isLoading: {
    type: Boolean,
    default: false,
  },
  /**
   * 文案
   */
  text: {
    type: String,
    default: '',
  }
})

// data
const classes = computed(() => {
  return {
    'add-more__wrap': true,
    'add-more__wrap_loading': props.isLoading,
  }
})

// methods
const handleClick = (e) => {
  if (props.isLoading) {
    return
  }
  emit('click', e)
}
</script>

<style lang="less" scoped>
.add-more {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    padding: 4px 10px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 16px;
    &:hover {
      border-color: #222;
      cursor: pointer;
    }
  }
  &__text {
    font-size: 12px;
    font-weight: 600;
    color: #222;
  }
}
</style>
