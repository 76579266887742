import { template, parseQueryString } from '@shein/common-function'
import { debuggerLog } from './helpers'
import { SMessage } from '@shein-aidc/sui-message'
import schttp from 'public/src/services/schttp'
import createApp from './createApp'
import apiReport from 'public/src/pages/common/apiReport'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  initPciTransformer,
  postQueryAddPurchaseRelation,
  aggregateQueryPopupInfo
} from './fetcher'
import Methods from './methods'
import { GOOGLE_PAY_METHODS } from 'public/src/pages/checkout/components/GooglePay/config'
import useCommon from './hooks/useCommon'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const { ocpAnalysis } = useCommon()

daEventCenter.addSubscriber({ modulecode: '2-23' })
// import MockData from './mock'
const { langPath } = gbCommonInfo
const ONE_CLICK_PAY_MERGE_COUNT_LIMIT = 5 // 一键购最大可合单商品数，取阿波罗
const noop = () => { }

/**
 * 一键购支付流程
 * wiki.pageId=1087341437
 *
 * @class OneClickPay
 */
export class OneClickPay {

  static MERGE_COUNT_LIMIT = ONE_CLICK_PAY_MERGE_COUNT_LIMIT

  options = {
    scene: '', // ProductDetail
    location: '', // 打开一键购是页面还是弹窗 默认弹窗
  }

  openOneClickPayCompliance = true  // 固定开启一键购法务弹窗

  initPciSuccess = false

  payParams = {
    good_sn: '',
    goods_id: 0, // 商品id
    sku_code: '', // sku
    business_model: 0, // 业务模式
    mall_code: '', // mall
    payment_code: '', // 支付方式
    billno: '', // 订单号
    sign_up_email: '', // 脱敏PayPal邮箱
    show_card_no: '', // 	带*卡号,展示用
    quantity: 0, // 商品总数
    amount: 0, // 订单总金额
    currency: '', // 币种
    country_code: '', // 收货人国家编码
    payment_no: '', // 原订单对应主支付单
  }
  onSuccess = noop
  onFail = noop
  onComplete = noop // 支付模块交互全部完成后执行，type - success,fail,cancel extraData - addPurchaseRelation
  containerInstance = null

  language = {}
  #STATUS_ENUMS = {
    // 302457
    'PaymentFailed': ['302457', '302461', '302456', '302460'], // 需更新按钮状态的报错
  }

  constructor() {
    if (typeof window == 'undefined') return
    this.cbPayResult()
    this.initPay()
    this.forterLoad()
    this.initRiskifiedDeviceId()
    this.getLanguage()
    this.getApolloConfig()
    if (this.containerInstance) {
      this.containerInstance.teardown?.()
    }
  }

  get hiddenSuccessToast() {
    return this.options?.scene === 'ProductDetail'
  }

  async initPay(options = { checkLogin: false }) {
    if (this.initPciSuccess) return true
    const res = await initPciTransformer()
    debuggerLog('initPay---', res)
    if (res?.code == 300206 && options.checkLogin) {
      this.closeAllToast()
      SHEIN_LOGIN && SHEIN_LOGIN.show({ show: true, cb: noop })
    }
    this.initPciSuccess = res?.code == 0
    return res
  }

  initCybersource() {
    if (typeof window == 'undefined') return
    if (window?.GB_cybersource_uuid) return
    window?.TPM?.run({
      marketing: 'Cybersource',
      method: '_loadCybersource',
    })
  }

  forterLoad() {
    // 获取forterSiteId和forterDeviceId
    if (typeof window == 'undefined') return
    window?.TPM?.run({
      marketing: 'Forter',
      method: '_loadFort',
    })
    document?.addEventListener('ftr:tokenReady', function (evt) {
      window.forterDeviceId = evt.detail || window.forterDeviceId || ''
    })
  }

  initRiskifiedDeviceId() {
    if (typeof window == 'undefined') return
    let riskifiedInfo = window.TPM?.runImmediate({
      marketing: 'Riskified',
      method: '_getRiskSessionId',
    })
    if (riskifiedInfo) {
      window.riskifiedDeviceId = riskifiedInfo?.datas || ''
    }
  }

  async create(infuseData = {
    params: {
      good_sn: '',
      goods_id: 0, // 商品id
      sku_code: '', // sku
      business_model: 0, // 业务模式
      mall_code: '', // mall
      payment_code: '', // 支付方式
      billno: '', // 订单号
      sign_up_email: '', // 脱敏PayPal邮箱
      show_card_no: '', // 	带*卡号,展示用
      quantity: 0, // 商品总数
      amount: 0, // 订单总金额
      currency: '', // 币种
      country_code: '', // 收货人国家编码
      goods_thumb: '', // 加购的商品图url(首图)
      payment_no: '' // 原订单对应主支付单
    },
    options: {
      scene: '', // ProductDetail
      location: '', // 打开一键购是页面还是弹窗
    },
    onSuccess: noop,
    onFail: noop,
    onComplete: noop, // 支付模块交互全部完成后执行，type - success,fail,cancel extraData - addPurchaseRelation
  }) {
    this.onSuccess = infuseData?.onSuccess || noop
    this.onFail = infuseData?.onFail || noop
    this.onComplete = infuseData?.onComplete || noop
    this.payParams = infuseData?.params || {}
    // this.payParams.currency = getCookie('currency') || gbCommonInfo.default_currency || gbCommonInfo?.currency
    this.payParams.currency = UserInfoManager.get({ key: 'currency' })
    this.options = infuseData?.options || {}
    this.detailPopupOptions = {}
    debuggerLog('payParams---', this.payParams)
    debuggerLog('options---', this.options)
    this.showProcessToast()

    this.clientApiReport({
      apiPath: 'one-click-pay/ocp-create',
      billno: this.payParams?.billno
    })

    // 创建弹窗等模块
    if (!this.containerInstance) {
      this.containerInstance = await createApp()
      if (!this.containerInstance) {
        this.reportClientError({
          apiPath: 'one-click-pay/init-container-error'
        })
        this.onComplete({ type: 'cancel', extraData: {} })
        this.closeAllToast()
        return
      }
    }
    // 需初始化pci环境
    const initPciRes = await this.initPay({ checkLogin: true })
    if (!this.initPciSuccess) {
      this.reportClientError({
        apiPath: 'one-click-pay/init-pci-error',
        err: initPciRes
      })
      this.onComplete({ type: 'cancel', extraData: {} })
      this.closeAllToast()
      return
    }

    this.initCybersource()

    this.containerInstance.$nextTick(async () => {
      this.showProcessToast()
      this.containerInstance.updateLanguage(this.language)
      const detailPopupOptions = await aggregateQueryPopupInfo(this.payParams)
      this.detailPopupOptions = detailPopupOptions
      if (detailPopupOptions.isCalculateMallSuccess) {
        this.preLoadChannelSdk({
          abtInfo: detailPopupOptions.abtInfo,
          paymentMethods: detailPopupOptions.paymentMethods
        })
        debuggerLog('detailPopupOptions---', detailPopupOptions)
        this.closeAllToast()
        this.containerInstance.openOCP({
          options: {
            maxCount: OneClickPay.MERGE_COUNT_LIMIT,
            payParams: this.payParams,
            otherOptions: this.options,
            ...(detailPopupOptions || {})
          },
          onClose: () => {
            this.onComplete({ type: 'cancel', extraData: {} })
          },
          onPayComplete: this.handlePayComplete.bind(this),
          showProcessToast: this.showProcessToast.bind(this),
          closeAllToast: this.closeAllToast.bind(this)
        })
        // 法务弹窗曝光pv
        ocpAnalysis({ daId: '2-23-7', 
          detailPopupOptions: {
            defaultPaymentInfo: detailPopupOptions?.defaultPaymentInfo?.code,
            location: this.options?.location
          }
        })
      } else {
        this._handleFail({
          limitMerge: false,
          status: 'Fail',
          scene: 'RearEnd',
          response: detailPopupOptions.calculateMall
        })
        this.clientApiReport({
          apiPath: 'one-click-pay/cal-fail',
          billno: this.payParams?.billno,
          errorCode: detailPopupOptions?.calculateMall?.code
        })
      }
    })
  }

  getLanguage() {
    return schttp({
      url: '/api/common/language/get',
      params: { page: 'one_click_pay' }
    }).then(({ language }) => {
      this.language = language
      if (this.containerInstance) {
        this.containerInstance.language = this.language
      }
      return language
    })
  }
  /**
   * 获取可加购数最大值
   *
   * @memberof OneClickPay
   */
  async getApolloConfig() {
    const res = await schttp({
      method: 'POST',
      url: '/api/config/apollo/get',
      data: { apolloKeys: 'ONE_CLICK_PAY_MAXIMUM_NUMBER_OF_TIMES' }
    })
    const { ONE_CLICK_PAY_MAXIMUM_NUMBER_OF_TIMES = 0 } = res?.info || {}
    OneClickPay.MERGE_COUNT_LIMIT = Number(ONE_CLICK_PAY_MAXIMUM_NUMBER_OF_TIMES || 5)
  }

  showCustomizeToast({
    type = '',
    htmlString = '',
    duration = 3000,
    onClose = () => ({})
  } = {}) {
    this.closeAllToast()
    SMessage({
      duration: duration || 3000,
      type,
      htmlString,
      onClose
    })
  }

  showFailToast({
    errorMsg = '',
    extraData = {}
  }) {
    this.closeAllToast()
    SMessage({
      duration: 3000,
      type: 'error',
      htmlString: errorMsg,
      onClose: () => {
        this.onComplete({ type: 'fail', extraData })
      }
    })
  }

  showSuccessToast() {
    return new Promise(resolve => {
      let isDone = false
      this.closeAllToast()
      SMessage({
        duration: 3000,
        type: 'success',
        message: this.language?.SHEIN_KEY_PC_15473,
        onClose() {
          isDone = true
          setTimeout(resolve, 100)
        }
      })
      // 防止关闭异常
      setTimeout(() => {
        if (!isDone) resolve()
      }, 3800)
    })
  }

  showProcessToast() {
    this.closeAllToast()
    SMessage({
      duration: 0,
      type: 'loading',
      loadingColor: '#2D68A8',
      message: `${this.language?.SHEIN_KEY_PC_15031 || ''}...`
    })
  }

  closeAllToast() {
    SMessage?.closeAll()
  }
  /**
   * 客户端日志上报
   *
   * @param {*} params
   * @memberof OneClickPay
   */
  reportClientError(params) {
    const { apiPath, ...resetParams } = params || {}
    const { payment_code = '', billno = '' } = this.payParams || {}
    debuggerLog('reportClientError---', apiPath, resetParams)
    apiReport.report({
      apiPath,
      billno,
      nowPageType: 'oneClickPay',
      payment_method: payment_code,
      err: JSON.stringify(resetParams?.err || {})
    })
  }

  clientApiReport(params) {
    const { apiPath, ...resetParams } = params || {}
    const { billno = '' } = this.payParams || {}
    apiReport?.report?.({
      apiPath,
      billno,
      nowPageType: 'ocp',
      ...resetParams
    })
  }

  _handleCustomize({ status, response }) {
    debuggerLog('customize--response---', response)
    if (status === 'pending') {
      this.showCustomizeToast({
        type: 'success',
        htmlString: this.language?.SHEIN_KEY_PC_24437,
        onClose: () => ({})
      })
      this.onComplete({ type: 'cancel', extraData: {} })
    }
  }
  /**
   * 支付成功逻辑处理
   *
   * @param {*} payload
   * @memberof OneClickPay
   */
  _handleSuccess(payload) {
    const result = Object.assign({}, {
      limitMerge: false
    }, payload)

    if (!this.hiddenSuccessToast) {
      const [info] = result?.response?.info?.order?.order_group || []
      daEventCenter.triggerNotice({
        daId: '2-23-1',
        extraData: {
          order_no: this.payParams.billno,
          merge_count: info?.merge_count || 0,
          style: 'toast'
        },
      })
    }

    ocpAnalysis({ daId: '2-23-12', 
      detailPopupOptions: {
        defaultPaymentInfo: this.detailPopupOptions?.defaultPaymentInfo?.code,
        location: this.options?.location
      },
      other: {
        activity_from: 'one_tap_pay',
        result: 1,
        result_reason: payload?.response?.info?.error_code || payload?.response?.code || '0'
      },
    })

    this.onSuccess?.(result)

    // 未达到加购上限或商详页的逻辑
    // --- 达到加购上限商详页不需要弹出机会已用完提示
    if (!result.limitMerge || this.hiddenSuccessToast) {
      Promise.all([
        postQueryAddPurchaseRelation(this.payParams),
        this.hiddenSuccessToast ? noop() : this.showSuccessToast()
      ]).then(([addPurchaseRelation]) => {
        this.onComplete?.({ type: 'success', extraData: { result, addPurchaseRelation } })
      }).catch(err => {
        this.onComplete?.({ type: 'success', extraData: { result, addPurchaseRelation: {} } })
        this.reportClientError({
          apiPath: 'one-click-pay/get-purchase-relation-error',
          err
        })
      })

    } else {
      this.closeAllToast()

      this.containerInstance.showResultTips({
        type: 'success',
        title: this.language.SHEIN_KEY_PC_15473,
        description: template(OneClickPay.MERGE_COUNT_LIMIT, this.language.SHEIN_KEY_PC_25007),
        onClose: () => {
          postQueryAddPurchaseRelation(this.payParams).then(data => {
            this.onComplete?.({ type: 'success', extraData: { result, addPurchaseRelation: data } })
          })
        },
        onSubmit: () => {
          postQueryAddPurchaseRelation(this.payParams).then(data => {
            this.onComplete?.({ type: 'success', extraData: { result, addPurchaseRelation: data } })
          })
          this.containerInstance.hideResultTips()
        },
        onCancel: () => {
          this.containerInstance.hideResultTips()
          location.href = `${langPath}/user/orders/list`
        }
      })
    }
  }
  /**
   * 支付失败/接口报错统一处理
   *
   * @param {*} payload
   * @memberof OneClickPay
   */
  _handleFail(payload) {
    const { response } = payload || {}

    const result = Object.assign({}, {
      limitMerge: false
    }, payload)

    debuggerLog('_handleFail---', result)
    this.onFail?.(result)

    this.closeAllToast()
    if (result.limitMerge) {
      this.containerInstance.showResultTips({
        type: 'fail',
        title: this.language.SHEIN_KEY_PC_25014,
        description: response?.tips || this.language['SHEIN_KEY_PC_27106'],
        onSubmit: () => {
          this.onComplete?.({ type: 'fail', extraData: { result } })
        },
        onClose: () => {
          this.onComplete?.({ type: 'fail', extraData: { result } })
        }
      })
    } else {
      const { info, tips, code = '', msg = '' } = response || {}
      let errorMsg = info?.show_error_msg || tips || msg || this.language['SHEIN_KEY_PC_27106'] || this.language['302459']
      if (code == '302202') {
        errorMsg = info?.msg || this.language['SHEIN_KEY_PC_27106'] || this.language['302459']
      } else if (code == 302207) {
        errorMsg = (tips || '').replace('{0}', info || '')
      }
      // code返回0，msg OK 走兜底多语言
      if(code == 0 && msg == 'OK'){
        errorMsg = this.language['SHEIN_KEY_PC_27106'] || this.language['302459']
      }
      this.showFailToast({
        errorMsg,
        extraData: { result }
      })
    }

    // wiki.pageId=1093671367
    // 曝光支付失败提示
    daEventCenter.triggerNotice({
      daId: '2-23-6',
      extraData: {
        style: result.limitMerge ? 'popup' : 'toast',
        reason_tp: payload?.response?.info?.error_code || payload?.response?.code || '0'
      },
    })

    ocpAnalysis({ daId: '2-23-12', 
      detailPopupOptions: {
        defaultPaymentInfo: this.detailPopupOptions?.defaultPaymentInfo?.code,
        location: this.options?.location
      },
      other: {
        activity_from: 'one_tap_pay',
        result: 2,
        result_reason: '',
      }
    })
  }

  handlePayComplete({ status, response, failScene = '' }) {
    debuggerLog('handlePayComplete-----', { status, response })
    this.closeAllToast()
    let errorMessage = ''
    let errorCode = ''
    if (status === 'success') {
      this._handleSuccess({
        limitMerge: false,
        status: 'Success',
        scene: 'RearEnd',
        response: {
          code: 0
        }
      })
    } else if (status === 'fail') {
      const isUpdateStatusError = this.#STATUS_ENUMS.PaymentFailed.includes(`${response?.code}`)
      errorMessage = response?.info?.show_error_msg || response?.tips || response?.msg
      errorCode = response?.info?.error_code || response?.code
      this._handleFail({
        limitMerge: isUpdateStatusError,
        status: 'Fail',
        scene: 'RearEnd',
        response,
        failScene
      })
    } else if (status === 'cancel') {
      this.onComplete({ type: 'cancel', extraData: {} })
    } else {
      this._handleCustomize({ status, response })
    }

    this.clientApiReport({
      apiPath: 'one-click-pay/pay-complete',
      status,
      failScene,
      errorMessage,
      errorCode
    })
  }
  /**
   * 第三方回调返回结果处理
   *
   * @memberof OneClickPay
   */
  async cbPayResult() {
    if (typeof window == 'undefined') return
    const cacheRes = Methods.getCache()
    if (Object.keys(cacheRes || {}).length) {
      const { isOcp } = parseQueryString(location.search || '')
      if (isOcp) {
        await this.getLanguage().catch(() => { })
        const { action, result, pending } = cacheRes?.info || {}
        const { } = cacheRes || {}
        let ocpStatus = 'fail'
        if (cacheRes?.code == 0 && action == 'direct' && result == 1) {
          ocpStatus = 'success'
        }
        if (action == 'direct' && result == 0 && pending == 1) {
          ocpStatus = 'pending'
        }
        this.handlePayComplete({
          status: ocpStatus,
          response: cacheRes
        })
      }
      Methods.clearCache()
      try {
        const formatUrl = new URL(location.href)
        formatUrl?.searchParams?.delete?.('isOcp')
        // formatUrl?.searchParams?.delete?.('ocpBillno')
        window.history?.replaceState?.(null, '', formatUrl.href)
      } catch (e) {
        console.error(e)
      }
    }
  }

  preLoadChannelSdk({
    abtInfo,
    paymentMethods
  }) {
    if (typeof window == 'undefined') return
    if (paymentMethods?.find(v => v.code === 'PayPal-GApaypal')) {
      window.TPM?.run({
        marketing: 'InitChannelSdk',
        method: '_initPPGASdk'
      })
    }
    if (paymentMethods?.find(v => v.code === 'PayPal-Venmo')) {
      window.TPM?.run({
        marketing: 'InitChannelSdk',
        method: '_initVenmoSdk'
      })
    }

    if (paymentMethods?.find(v => GOOGLE_PAY_METHODS.includes(v.code))) {
      window.TPM?.run({
        marketing: 'InitChannelSdk',
        method: '_initGPSdk'
      })
    }

    if (abtInfo.PayInline?.p.indexOf('klarna=')) {
      window.TPM?.run({
        marketing: 'InitChannelSdk',
        method: '_initKlarnaSdk'
      })
    }
    
    if (paymentMethods?.find(v => v.code === 'afterpay-cashapp')) {
      window.TPM?.run({
        marketing: 'InitChannelSdk',
        method: '_initAfterpaySdk'
      })
    }
  }

}

export default new OneClickPay()
