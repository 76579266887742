import { isThirtyLowestPrice } from 'public/src/pages/cart_new/utils/index.js'
import { template } from '@shein/common-function'

export const INCENTIVE_TYPES = [
  'oos',
  'mark_down',
  'flash_sale',
  'coupon',
  'lowest_price_30days'
]

export const POPOVER_CONFIGS = {
  'oos': {
    'iconName': 'sui_icon_hot_darkorange_gradation_12px_color',
    'color': '',
    'dialogTitle': 'SHEIN_KEY_PC_31174',

  },
  'mark_down': {
    'iconName': 'sui_icon_decrease_gradation_12px_color',
    'color': '',
    'dialogTitle': 'SHEIN_KEY_PC_31174',

  },
  'flash_sale': {
    'iconName': 'sui_icon_flashsale_gradation_12px_color',
    'color': '',
    'dialogTitle': 'SHEIN_KEY_PC_31174',

  },
  'coupon': {
    'iconName': '',
    'color': '',
    'dialogTitle': 'SHEIN_KEY_PC_31174',

  },
  'lowest_price_30days': {
    'iconName': 'sui_icon_lowest_price_12px_2',
    'color': 'rgba(250, 99, 56, 1)',
    'dialogTitle': 'SHEIN_KEY_PC_31174',
  },
}

export const BUTTON_CONFIGS = {
  'coupon': {
    'iconName': 'sui_icon_coupon_discount_12px_color',
    'text': 'SHEIN_KEY_PC_31179',
    'color': '',
  },
  'flash_sale': {
    'iconName': 'sui_icon_flashsale_gradation_12px_color',
    'text': 'SHEIN_KEY_PC_31178',
    'color': '',
  },
  'mark_down': {
    'iconName': 'sui_icon_decrease_gradation_12px_color',
    'text': 'SHEIN_KEY_PC_31177',
    'color': '',
  },
  'oos': {
    'iconName': 'sui_icon_hot_darkorange_gradation_12px_color',
    'text': 'SHEIN_KEY_PC_31176',
    'color': '',
  },
}

export function detectUserInteraction({
  noInteractionTimeout = 3000,
  scrollThreshold = 2,
  onNoInteraction = null,
  onScrollThresholdReached = null
} = {}) {
  let interactionDetected = false
  let scrollThresholdReached = false
  const screenHeight = window.innerHeight || document.documentElement.clientHeight

  const eventTypes = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart', 'touchmove']

  const resetInteractionFlag = () => {
    interactionDetected = true
    clearTimeout(interactionTimer)
    removeListeners(true)
  }

  const checkScrollDistance = () => {
    const scrolledDistance = window.scrollY || window.pageYOffset
    if (scrolledDistance > screenHeight * scrollThreshold && !scrollThresholdReached) {
      scrollThresholdReached = true
      if (typeof onScrollThresholdReached === 'function') {
        onScrollThresholdReached()
      }
      removeListeners() // 停止跟踪
    }
  }

  const removeListeners = (interactionOnly = false) => {
    eventTypes.forEach((eventType) => {
      window.removeEventListener(eventType, resetInteractionFlag, { passive: true })
    })
    if(!interactionOnly) window.removeEventListener('scroll', checkScrollDistance, { passive: true })
  }

  const interactionTimer = setTimeout(() => {
    if (!interactionDetected) {
      if (typeof onNoInteraction === 'function') {
        onNoInteraction()
      }
      removeListeners()
    }
  }, noInteractionTimeout)

  eventTypes.forEach((eventType) => {
    window.addEventListener(eventType, resetInteractionFlag, { passive: true })
  })

  window.addEventListener('scroll', checkScrollDistance, { passive: true })
}

/**
 * 判断未来时间戳与当前时间的差值是否小于指定的时长。
 * 
 * @param {number} timestamp - 未来时间戳，单位毫秒。
 * @param {number} duration - 指定的时长，单位毫秒。
 * @returns {boolean} 如果未来时间戳与当前时间的差值小于指定的时长，则返回 true，否则返回 false。
 */
export function isTimestampWithinDuration(timestamp, duration) {
  function isValidNumber(str) {
    return !isNaN(parseFloat(str)) && isFinite(str)
  }
  // 检查 timestamp 是否是一个有效的数字
  if (!isValidNumber(timestamp)) {
    throw new Error('timestamp 必须是一个有效的数字')
  }

  // 检查 duration 是否是一个非负数
  if (duration < 0 || !isValidNumber(duration)) {
    throw new Error('duration 必须是一个非负数')
  }

  const currentTime = Date.now() // 获取当前时间的时间戳

  // 确保时间戳在未来
  if (timestamp * 1000 <= currentTime) {
    throw new Error('timestamp 必须在未来')
  }

  const timeDifference = timestamp * 1000 - currentTime // 计算未来时间戳与当前时间的差值
  return timeDifference < duration
}

/* export class IncentivePopover {
  constructor(options = {}) {
    this.popover = null
    this.popoverTimer = null
    this.incentivePopoverSwitchTimer = null
  }
  getIncentiveItem() {
    return 
  }
  closePopover() {

  }
} */
/* 
export const vSweepLight = {
  bind(el, binding) {
    const { value } = binding
    const duration = value.duration || '2s'
    const direction = value.direction || 'right'
    const isActive = value.active !== undefined ? value.active : true

    let angle = '90deg'
    if (direction === 'left') angle = '270deg'

    const baseStyle = {
      position: 'relative',
      color: '#fff',
      overflow: 'hidden',
      background: `linear-gradient(${angle}, rgba(255,255,255,0) 49%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0) 51%)`,
      backgroundSize: '200% 100%',
      backgroundImage: `linear-gradient(${angle}, rgba(255,255,255,0) 49%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0) 51%)`
    }

    // 应用基础样式
    Object.keys(baseStyle).forEach(key => {
      el.style[key] = baseStyle[key]
    })

    // 控制动画的播放
    if (isActive) {
      el.style.animation = `sweepLightEffect ${duration} linear infinite`
    } else {
      el.style.animation = 'none'
    }
  },
  inserted(el, binding) {
    // 动态创建@keyframes规则并插入到head中
    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerHTML = `
      @keyframes sweepLightEffect {
          from { background-position: -200% 0; }
          to { background-position: 200% 0; }
      }
    `
    document.head.appendChild(styleSheet)
  }
}

 */
export function getXLowestPopoverTips(goods = [], allTsp = {}, language = {}) {
  if(goods.length == 1) {
    let cartItem = goods[0]
    let tsp = allTsp[cartItem?.product?.goods_id]?.realTimeTspLabels || {}
    let result = isThirtyLowestPrice(tsp, cartItem)
    let XDay = result[90] || result[60] || result[30]
    if(XDay) return {
      bubbleTips: template(XDay, language.SHEIN_KEY_PC_31395),
      actualPointLowest: XDay,
    }
  } else if (goods.length > 1) {
    let XDay = Number.MAX_SAFE_INTEGER
    goods.forEach(item => {
      let tsp = allTsp[item?.product?.goods_id]?.realTimeTspLabels || {}
      let result = isThirtyLowestPrice(tsp, item)
      let iXDay = result[90] || result[60] || result[30]
      if(XDay && iXDay < XDay) XDay = iXDay
    })
    if(XDay) return {
      bubbleTips: template(XDay, language.SHEIN_KEY_PC_31396),
      groupTitle: template(XDay, language.SHEIN_KEY_PC_31397),
      actualPointLowest: XDay,
    }
  } else {
    return {}
  }
}

