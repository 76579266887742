
import { reactive, ref } from 'vue'
import Methods from '../methods'

const rpcCardSelect = reactive({
  valid: false,
  type: '1'
})
const rpcTokenInfo = ref({})
const rpcPayment = ref({})

export default () => {
  const handleInitRpcToken = ({ paymentMethods }) => {
    if (!paymentMethods.value?.length) return
    rpcPayment.value = Methods.getRpcPayment({ paymentMethods: paymentMethods.value }) || {}
    rpcTokenInfo.value = Methods.getRpcCardToken({ rpcPayment: rpcPayment.value }) || {}
    rpcCardSelect.valid = !!rpcTokenInfo.value?.id
    rpcCardSelect.type = !!rpcTokenInfo.value?.id ? '1' : '0'
    // console.log('rpc----', rpcCardSelect)
  }

  const resetState = () => {
    rpcCardSelect.valid = false
    rpcCardSelect.type = '1'
    rpcTokenInfo.value = {}
    rpcPayment.value = {}
  }

  return {
    rpcCardSelect,
    rpcTokenInfo,
    rpcPayment,
    handleInitRpcToken,
    resetState
  }
}
