/**
 * 负向信息组件 GoodsNegativeInfo
 * GoodsNegativeInfo 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }) {
  const notPointsText = data.value.product?.double_status == 0 ? language.value.SHEIN_KEY_PC_22343 : ''
  const notCouponText = getNotCouponText({ data, language })
  const notReturnText = data.value.return_flag != 0 ? language.value.SHEIN_KEY_PC_15990 : ''
  return {
    notPointsText,
    notCouponText,
    notReturnText,
  }
}

function getNotCouponText({ data, language }) {
  if (data?.store_type != 2) return ''
  if (data?.product?.have_tag == 1 && data?.isCartnoteligibleforcoupons) return language.value.SHEIN_KEY_PC_23723
  return ''
}
