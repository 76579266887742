import { template } from '@shein/common-function'
import { daEventCenter } from '@/public/src/services/eventCenter/index'

const applyForGoodsCoupon = [1, 2, 3, 7, 8] // 商品券

export const handlerCouponSaveToast = ({
  language,
  cartAbtInfo,
  cartInfo,
  couponInfo,
}, {
  $toast
}) => {
  const {
    coupon_infos = [],
    coupon_discount_price = {},
  } = cartInfo || {}

  let toastText = ''
  let couponAddToastType = 0

  if (
    couponInfo &&
    applyForGoodsCoupon.includes(+couponInfo.apply_for) && // 是商品券
    couponInfo._data.is_satisfied !== 0 // 满足最低门槛
  ) {
    if (coupon_infos.length) { // 有最优券组合
      couponAddToastType = 1
      if (cartAbtInfo.CouponAddToCartToast?.param?.coupon_add_to_cart_toast === 'on') {
        const target = coupon_infos.find(f => f.coupon_code === couponInfo.coupon_code)

        if (target) {
          if (target.discount_price?.amountWithSymbol) toastText = template(target.discount_price.amountWithSymbol, language.SHEIN_KEY_PC_30733)
        } else {
          if (coupon_discount_price.amountWithSymbol) toastText = template(coupon_discount_price.amountWithSymbol, language.SHEIN_KEY_PC_30734)
        }
      }
    } else {
      couponAddToastType = 2
    }
  }

  if (toastText) $toast(toastText)

  daEventCenter.triggerNotice({
    daId: '1-7-3-22',
    extraData: {
      scenes: 'coupon_add_toast',
      type: couponAddToastType,
    }
  })
}
