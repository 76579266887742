<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="showRetailPricePopover"

      v-model="popoverActive"
      :content="pricePopoverLang"
      placemen="bottom"
      trigger-type="unset"
      show-close-icon
      fix-disappear-position
      :prop-style="popoverStyle"
      class="popover-container"
      @click.stop="popoverClick"
    >
      <template #reference>
        <div 
          class="suggested-popover" 
        >
          <SuggestedSalePrice
            :value="priceValue"
          />
          <i 
            ref="iconCaution"
            :data-id="id"
            class="icon-suggested-caution sh_pc_sui_icon_caution_14px_1"
            :class="{
              'display-none': showIconSuggested === -1,
              'show': showIconSuggested === 1
            }"
          ></i>
        </div>
      </template>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { SPopover } from '@shein-aidc/sui-popover'

import { ref, toRefs, computed, watch, nextTick, onMounted } from 'vue'
import { isFrPlSuggestedSalePrice, isDeSuggestedSalePrice } from '../../utils/priceDisplayTheResults'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  specialData: {
    type: Object,
    default: () => {},
  },
  supportVueDebug: {
    type: Boolean,
    default: false,
  }
})

const { goodsInfo, specialData } = toRefs(props)
const { retailPrice } = goodsInfo.value

const suggestedSalePriceConfigInfo = goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo
const suggestedSalePriceType = suggestedSalePriceConfigInfo?.type

const isFrPl = isFrPlSuggestedSalePrice(suggestedSalePriceType)
const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)

const suggestedSalePriceInfo = goodsInfo.value?.suggestedSalePrice

const pricePopoverLang = isDe ? suggestedSalePriceConfigInfo?.pricePopoverLang : ''

// 德国展示原价  法国挪威展示合规价
const priceValue = isFrPl ? suggestedSalePriceInfo : retailPrice

const showRetailPricePopover = specialData.value?.showRetailPricePopover

// 唯一id
const id = ref('')
// 是否激活pop弹窗
const popoverActive = ref(false)

// 是否展示icon
const iconCaution = ref(null)
const showIconSuggested = ref(0)

const popoverClick = (e) => {
  const { target } = e
  if (!popoverActive.value) {
    const drawerDom = target.closest('.sui-drawer__container') 
    const dialogDom = target.closest('.sui-dialog__body') 
    if (drawerDom || dialogDom){
      popoverZindex.value = 999999
    } else {
      popoverZindex.value = 1
    }


    nextTick(() => {
      popoverActive.value = true
      window.deSuggestedSaleComponnet.popoverActiveId.value = id.value
    })
  }
}

const popoverZindex = ref(1)

const popoverStyle = computed(() => {
  return {

    zIndex: popoverZindex.value,
    transform: `translateZ(${popoverZindex.value}px)`,
    maxWidth: '240px'
  }
})

onMounted(() => {
  const { goods_id, goods_sn } = goodsInfo.value
  id.value = `${goods_id}_${goods_sn}`

  const handleFun = () => {
    if (!showRetailPricePopover) return false
    
    // 判断icon是否够位置展示
    // 没有被压缩变形，宽度高度一致，证明可以展示icon
    const { width, height } = iconCaution.value?.getBoundingClientRect()
    if (width >= height) {
      showIconSuggested.value = 1
    } else {
      showIconSuggested.value = -1
    }

    // 点击滑动除popover外任意部分需要隐藏popover
    if (!window.deSuggestedSaleComponnet) {
      window.deSuggestedSaleComponnet = {
        popoverActiveId: ref(''),
        touchEvent: window.document.addEventListener('click', (e) => {
          const { target } = e

          const popoverActiveId = window.deSuggestedSaleComponnet.popoverActiveId.value
          if (popoverActiveId && !target.className.includes('sui-popover__content')) {
            const parentElement = target.parentElement.parentElement
            
            const priceWrapperElement = parentElement.querySelector('.bottom-wrapper__price-wrapper')
            const popoverElement = parentElement.querySelector('.icon-suggested-caution')
            const popoverId = popoverElement?.getAttribute('data-id')
            if (!priceWrapperElement && popoverId === popoverActiveId) return false

            window.deSuggestedSaleComponnet.popoverActiveId.value = ''
          }
        }),
        scrollEvent: window.document.addEventListener('scroll', () => {
          if (window.deSuggestedSaleComponnet.popoverActiveId.value) {
            window.deSuggestedSaleComponnet.popoverActiveId.value = ''
          }
        })
      }
    }

    watch(
      window.deSuggestedSaleComponnet.popoverActiveId,
      (newVal) => {
        // 避免多次打开关闭
        nextTick(() => {
          if ((!newVal || newVal !== id.value) && popoverActive.value) {
            popoverActive.value = false
          }
        })
      }
    )
  }

  if (window.requestIdleCallback) {
    window.requestIdleCallback(handleFun)
  } else {
    setTimeout(handleFun, 50)
  }
})
</script>

<style lang="less" scoped>
:deep(.popover-container.sui-popover__trigger) {
  display: inline;
}

.prices-info__suggested-sale-price {
  flex: 1;
  min-width: 0;
}
.suggested-popover {
  cursor: pointer;

  display: flex;
  align-items: center;
  line-height: 14px;
}
.icon-suggested-caution {
  margin-left: 2px;
  width: 14px;
  height: 14px;

  opacity: 0;

  &.display-none {
    display: none;
  }
  &.show {
    opacity: 1;
  }
}
</style>
