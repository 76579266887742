/**
 * 购物车顶部筛选标签相关方法
 */
import { template } from '@shein/common-function'
const { CART_FILTER_BEHAVIOR_LABELS } = gbCommonInfo

// 定义所有筛选标签类型的枚举
const LabelKeyMap = {
  // 促销活动
  FLASH_SALE: 'flash_sale',
  DISCOUNT_LIMIT: 'discount_limit',
  LIMIT_OFF: 'limit_off',
  UNDER_PRICE: 'under_price',
  // 优惠券
  COUPON: 'coupon',
  // 商品信息
  ALMOST_OUT_OF_STOCK: 'almost_out_of_stock',
  MARKDOWNS: 'markdowns',
  // 用户行为标签
  FIVE_START: '5star',
  THIRTY_DAY_SALE: '30day_sale',
  THIRTY_DAY_ADD_ITEM: '30day_additem',
  VOUCHERS: 'category_voucher',
}

const filterLabelIcon = {
  [LabelKeyMap.FLASH_SALE]: {
    default: 'sui_icon_flashsale_12px',
    defaultChecked: 'sui_icon_flashsale_12px',
    a: 'sui_icon_flashsale_gradation_12px_color',
    aChecked: 'sui_icon_flashsale_gradation_12px_color',
    b: 'sui_icon_flashsale_12px',
    bChecked: 'sui_icon_flashsale_12px'
  },
  [LabelKeyMap.ALMOST_OUT_OF_STOCK]: {
    default: 'sui_icon_hot_12px',
    defaultChecked: 'sui_icon_hot_12px',
    a: 'sui_icon_hot_darkorange_gradation_12px_color',
    aChecked: 'sui_icon_hot_darkorange_gradation_12px_color',
    b: 'sui_icon_hot_12px',
    bChecked: 'sui_icon_hot_12px'
  },
  [LabelKeyMap.MARKDOWNS]: {
    default: 'sui_icon_decrease_12px',
    defaultChecked: 'sui_icon_decrease_12px',
    a: 'sui_icon_decrease_gradation_12px_color',
    aChecked: 'sui_icon_decrease_gradation_12px_color',
    b: 'sui_icon_decrease_12px',
    bChecked: 'sui_icon_decrease_12px'
  },
  [LabelKeyMap.COUPON]: {
    default: 'sui_icon_coupon_discount_12px_color',
    defaultChecked: 'sui_icon_coupon_discount_12px_color',
    a: 'sui_icon_coupon_discount_12px_color',
    aChecked: 'sui_icon_coupon_discount_12px_color',
    b: 'sui_icon_coupon_discount_12px_color',
    bChecked: 'sui_icon_coupon_discount_white_12px_color'
  },
  [LabelKeyMap.DISCOUNT_LIMIT]: {
    default: 'sui_icon_shoppingcart_discountlimit_12px',
    defaultChecked: 'sui_icon_shoppingcart_discountlimit_12px',
    a: 'sui_icon_shoppingcart_discountlimit_12px_color',
    aChecked: 'sui_icon_shoppingcart_discountlimit_12px_color',
    b: 'sui_icon_shoppingcart_discountlimit_12px',
    bChecked: 'sui_icon_shoppingcart_discountlimit_12px'
  },
  [LabelKeyMap.LIMIT_OFF]: {
    default: 'sui_icon_shoppingcart_time_12px',
    defaultChecked: 'sui_icon_shoppingcart_time_12px',
    a: 'sui_icon_shoppingcart_time_12px_color',
    aChecked: 'sui_icon_shoppingcart_time_12px_color',
    b: 'sui_icon_shoppingcart_time_12px',
    bChecked: 'sui_icon_shoppingcart_time_12px'
  },
  [LabelKeyMap.UNDER_PRICE]: {
    default: 'sui_icon_shoppingcart_underprice_12px',
    defaultChecked: 'sui_icon_shoppingcart_underprice_12px',
    a: 'sui_icon_shoppingcart_underprice_12px_color',
    aChecked: 'sui_icon_shoppingcart_underprice_12px_color',
    b: 'sui_icon_shoppingcart_underprice_12px',
    bChecked: 'sui_icon_shoppingcart_underprice_12px'
  },
  [LabelKeyMap.FIVE_START]: {
    default: 'sui_icon_shoppingcart_completed_12px',
    defaultChecked: 'sui_icon_shoppingcart_completed_12px',
    a: 'sui_icon_shoppingcart_completed_12px_color',
    aChecked: 'sui_icon_shoppingcart_completed_12px_color',
    b: 'sui_icon_shoppingcart_completed_12px',
    bChecked: 'sui_icon_shoppingcart_completed_12px'
  },
  [LabelKeyMap.THIRTY_DAY_SALE]: {
    default: 'sui_icon_hot_12px',
    defaultChecked: 'sui_icon_hot_12px',
    a: 'sui_icon_shoppingcart_hot_darkorange_12px_color',
    aChecked: 'sui_icon_shoppingcart_hot_darkorange_12px_color',
    b: 'sui_icon_hot_12px',
    bChecked: 'sui_icon_hot_12px'
  },
  [LabelKeyMap.THIRTY_DAY_ADD_ITEM]: {
    default: 'sui_icon_shoppingcart_cart_12px',
    defaultChecked: 'sui_icon_shoppingcart_cart_12px',
    a: 'sui_icon_shoppingcart_cart_12px_color',
    aChecked: 'sui_icon_shoppingcart_cart_12px_color',
    b: 'sui_icon_shoppingcart_cart_12px',
    bChecked: 'sui_icon_shoppingcart_cart_12px'
  },
  [LabelKeyMap.VOUCHERS]: {
    default: 'sui_icon_coupon_12px_color',
    defaultChecked: 'sui_icon_coupon_12px_color',
    a: 'sui_icon_coupon_12px_color',
    aChecked: 'sui_icon_coupon_12px_color',
    b: 'sui_icon_coupon_12px_color',
    bChecked: 'sui_icon_coupon_12px'
  }
}

// 筛选标签排序的orderKey
const filterOrderKey = {
  [LabelKeyMap.FLASH_SALE]: LabelKeyMap.FLASH_SALE,
  [LabelKeyMap.DISCOUNT_LIMIT]: LabelKeyMap.DISCOUNT_LIMIT,
  [LabelKeyMap.LIMIT_OFF]: LabelKeyMap.LIMIT_OFF,
  [LabelKeyMap.UNDER_PRICE]: LabelKeyMap.UNDER_PRICE,
  [LabelKeyMap.COUPON]: LabelKeyMap.COUPON,
  [LabelKeyMap.ALMOST_OUT_OF_STOCK]: 'oos',
  [LabelKeyMap.MARKDOWNS]: 'pricedown',
  [LabelKeyMap.FIVE_START]: LabelKeyMap.FIVE_START,
  [LabelKeyMap.THIRTY_DAY_SALE]: LabelKeyMap.THIRTY_DAY_SALE,
  [LabelKeyMap.THIRTY_DAY_ADD_ITEM]: LabelKeyMap.THIRTY_DAY_ADD_ITEM,
  [LabelKeyMap.VOUCHERS]: LabelKeyMap.VOUCHERS,
}

// 筛选标签分类信息，langKey-类名对应的多语言key，labelTypes-类名映射的标签type集合
export const categoryMap = {
  // 促销活动类
  HOT_DEALS: {
    langKey: 'SHEIN_KEY_PC_30800',
    labelTypes: [LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT, LabelKeyMap.LIMIT_OFF, LabelKeyMap.UNDER_PRICE, LabelKeyMap.VOUCHERS],
  },
  // 优惠券类
  COUPON_ELIGIBLE: {
    langKey: 'SHEIN_KEY_PC_30802',
    labelTypes: [LabelKeyMap.COUPON],
  },
  // 商品信息类
  ALMOST_GONE: {
    langKey: 'SHEIN_KEY_PC_30801',
    labelTypes: [LabelKeyMap.MARKDOWNS, LabelKeyMap.ALMOST_OUT_OF_STOCK],
  },
  // 用户行为标签类       
  TOP_FEATURES: {
    langKey: 'SHEIN_KEY_PC_30803',
    labelTypes: [LabelKeyMap.FIVE_START, LabelKeyMap.THIRTY_DAY_SALE, LabelKeyMap.THIRTY_DAY_ADD_ITEM],
  }
}

// 促销活动类+商品信息类标签
export function handleFilterLabels(cartItem, language) {
  let result = []
  const typeIds = cartItem?.product?.product_promotion_info?.map((v) => v.type_id)
  if (cartItem.reduce_price
      && Object.keys(cartItem.reduce_price).length > 0
      && cartItem.reduce_price?.reduceAmount?.amount > 0
  ) result.push({
    type: LabelKeyMap.MARKDOWNS,                      // type，标识类别，且同时作为埋点label值, 需设置为对应英语小写, 空格用下划线代替
    orderKey: filterOrderKey[LabelKeyMap.MARKDOWNS],  // 参加排序的标识
    tip: language.SHEIN_KEY_PC_25308,                 // 筛选标签展示的label内容
    iconName: filterLabelIcon[LabelKeyMap.MARKDOWNS], // icon name
  })
  // 闪购
  if (typeIds.includes(10)) result.push({
    type: LabelKeyMap.FLASH_SALE,
    orderKey: filterOrderKey[LabelKeyMap.FLASH_SALE],
    tip: language.SHEIN_KEY_PC_14588,
    iconName: filterLabelIcon[LabelKeyMap.FLASH_SALE],
  })
  if (cartItem.stock_show_type == 0) result.push({
    type: LabelKeyMap.ALMOST_OUT_OF_STOCK,
    orderKey: filterOrderKey[LabelKeyMap.ALMOST_OUT_OF_STOCK],
    tip: language.SHEIN_KEY_PC_25309,
    iconName: filterLabelIcon[LabelKeyMap.ALMOST_OUT_OF_STOCK],
  })
  // 打折限购
  if (typeIds.includes(24)) result.push({
    type: LabelKeyMap.DISCOUNT_LIMIT,
    orderKey: filterOrderKey[LabelKeyMap.DISCOUNT_LIMIT],
    tip: language.SHEIN_KEY_PC_30807,
    iconName: filterLabelIcon[LabelKeyMap.DISCOUNT_LIMIT],
  })
  // 限时折扣
  if (typeIds.includes(3)) result.push({
    type: LabelKeyMap.LIMIT_OFF,
    orderKey: filterOrderKey[LabelKeyMap.LIMIT_OFF],
    tip: language.SHEIN_KEY_PC_30808,
    iconName: filterLabelIcon[LabelKeyMap.LIMIT_OFF],
  })
  // 一口价
  if (typeIds.includes(30)) result.push({
    type: LabelKeyMap.UNDER_PRICE,
    orderKey: filterOrderKey[LabelKeyMap.UNDER_PRICE],
    tip: language.SHEIN_KEY_PC_30809,
    iconName: filterLabelIcon[LabelKeyMap.UNDER_PRICE],
  })
  if(typeIds.includes(33)) result.push({
    type: LabelKeyMap.VOUCHERS,
    orderKey: filterOrderKey[LabelKeyMap.VOUCHERS],
    tip: language.SHEIN_KEY_PC_30873,
    iconName: filterLabelIcon[LabelKeyMap.VOUCHERS],
  })
  return result
}

/**
 * 获取筛选标签的显示权重，值越小，优先级越高
 * @param {*} order 由abt配置的显示顺序
 * @returns { Object } { key1: 1, key2: 2 }
 */
function getPriority(order) {
  return Object.fromEntries(order.split(',').map((key, index) => [key, index + 1]))
}

/**
 * 合并 促销活动类+商品信息类+优惠券+用户行为标签
 * @param {*} promotionLabels       促销活动+商品信息类标签
 * @param {*} couponFilterLabelData 券筛选标签的来源数据(券助手接口)
 * @param {*} filterLabelOrder      abt配置的标签顺序
 * @param {*} couponSelectType      abt配置，select开启券筛选，couponhelper维持线上
 * @param {*} lang                  多语言文案
 * @param {*} atomicProducts        行为标签数据来源
 * @param {*} moreFilterControl     abt配置，多标签收纳样式，为off时不开启收纳功能
 * @returns 
 */
export function composeFilterLabels(
  promotionLabels,
  couponFilterLabelData,
  filterLabelOrder,
  couponSelectType,
  lang,
  atomicProducts,
  moreFilterControl,
) {
  const PRIORITY = getPriority(filterLabelOrder)
  const CART_FILTER_LABEL_COUNT = gbCommonInfo?.CART_FILTER_LABEL_COUNT || 5

  let couponLabels = [] // 券标签
  let behaviorLabels = getBehaviorLabels(atomicProducts, lang) // 用户行为标签

  if (['select', 'both'].includes(couponSelectType) && Reflect.has(PRIORITY, 'coupon')) {
    const _cLabels = getCouponLabel(couponFilterLabelData, lang)
    couponLabels = moreFilterControl === 'off' ? _cLabels.slice(0, 2) : _cLabels // 未开启多标签收纳时券标签最大数量为固定值2个
  }
  
  const allLabels = [...promotionLabels, ...couponLabels, ...behaviorLabels]
    .filter(i => Reflect.has(PRIORITY, i.orderKey))
    .sort((a, b) => 
      PRIORITY[a.orderKey] - PRIORITY[b.orderKey]
    )
  return moreFilterControl === 'off'
    ? allLabels.slice(0, CART_FILTER_LABEL_COUNT)
    : allLabels
}

/**
 * 从原子商品中筛选用户行为标签
 * @param {*} atomicProducts
 * @param {*} lang 
 * @returns 
 */
function getBehaviorLabels(atomicProducts, lang) {
  const labels = [
    {
      labelId: '3460',
      limit: CART_FILTER_BEHAVIOR_LABELS?.['3460'] || 5000,
      type: LabelKeyMap.THIRTY_DAY_ADD_ITEM,
      orderKey: filterOrderKey[LabelKeyMap.THIRTY_DAY_ADD_ITEM],
      tip: lang.SHEIN_KEY_PC_30806,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.THIRTY_DAY_ADD_ITEM],
    },
    {
      labelId: '3451',
      limit: CART_FILTER_BEHAVIOR_LABELS?.['3451'] || 8000,
      type: LabelKeyMap.THIRTY_DAY_SALE,
      orderKey: filterOrderKey[LabelKeyMap.THIRTY_DAY_SALE],
      tip: lang.SHEIN_KEY_PC_30805,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.THIRTY_DAY_SALE],
    },
    {
      labelId: '3726',
      limit: CART_FILTER_BEHAVIOR_LABELS?.['3726'] || 3000,
      type: LabelKeyMap.FIVE_START,
      orderKey: filterOrderKey[LabelKeyMap.FIVE_START],
      tip: lang.SHEIN_KEY_PC_30804,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.FIVE_START],
    },
  ]
  Object.values(atomicProducts).forEach((item) => {
    labels.forEach((label) => {
      if (item?.realTimeTspLabels?.[label.labelId] >= label.limit) {
        label.goodsIds.push(item.goods_id)
      }
    })
  })
  return labels.filter(v => v.goodsIds?.length > 0)
}

export class CheckoutTipCache {
  constructor(storeKey = 'cartFilterLabelPopover', expire = 1000 * 60 * 60 * 24 * 7) {
    this._StoreKey = storeKey
    this.expire = expire
    let cacheStore = JSON.parse(localStorage.getItem(this._StoreKey))
    if(cacheStore) this.cache = cacheStore
    else {
      this.cache = {}
      localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
    }
  }
  add(id) {
    this.cache[id] = new Date().getTime()
    localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
  }
  isExpireById(id) {
    // 先清理过期的id, 若取不到则说明过期, 若能取到则其值必定大于0, 为true
    this.cleanStorage()
    return !this.cache[id]
  }
  cleanStorage() {
    let today = new Date().getTime()
    Object.keys(this.cache).forEach(key => {
      if(today - this.cache[key] > this.expire) delete this.cache[key]
    })
    localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
  }
}

// 筛选商品行
export function showFilterItem(
  goodItem,
  curFilterLabel,
  targetCouponInfo = {},
  goodsFilterLabels = []
) {
  // 券筛选
  if (!isNaN(curFilterLabel) && goodsMatchCoupon(goodItem, targetCouponInfo)) return true

  // 其他类型的筛选
  const targetFilterInfo = goodsFilterLabels.find(i => curFilterLabel === i?.type)
  const targetIsBehavior = categoryMap['TOP_FEATURES']?.labelTypes.includes(targetFilterInfo?.type)
  const typeIds = goodItem?.product?.product_promotion_info?.map((v) => v.type_id)

  if (curFilterLabel == LabelKeyMap.MARKDOWNS && goodItem.reduce_price?.reduceAmount?.amount > 0) return true
  else if (curFilterLabel == LabelKeyMap.FLASH_SALE && typeIds.includes(10)) return true
  else if (curFilterLabel == LabelKeyMap.ALMOST_OUT_OF_STOCK && (goodItem.stock_show_type == 0)) return true
  else if (curFilterLabel == LabelKeyMap.DISCOUNT_LIMIT && typeIds.includes(24)) return true
  else if (curFilterLabel == LabelKeyMap.LIMIT_OFF && typeIds.includes(3)) return true
  else if (curFilterLabel == LabelKeyMap.UNDER_PRICE && typeIds.includes(30)) return true
  else if (targetIsBehavior && targetFilterInfo.goodsIds.includes(goodItem?.product?.goods_id)) return true
  else if (curFilterLabel == LabelKeyMap.VOUCHERS && typeIds.includes(33)) return true
  else return false
}

// 筛选
export function showFilterHeader(
  goodsList,
  curFilterLabel,
  type = 'list',
  targetCouponInfo = {},
  goodsFilterLabels = []
) {
  // mall维度进行递归
  if (type == 'mall' && Array.isArray(goodsList.mall_list)) {
    for (const store of goodsList.mall_list) {
      if (showFilterHeader(store.list, curFilterLabel, 'store', targetCouponInfo, goodsFilterLabels)) return true
    }
    return false
  }
  // 按store维度进行递归
  if (type == 'store' && Array.isArray(goodsList)) {
    for (const store of goodsList) {
      if (showFilterHeader(store.list, curFilterLabel, 'list', targetCouponInfo, goodsFilterLabels)) return true
    }
    return false
  }
  // 按聚合活动维度进行判断
  if (Array.isArray(goodsList)) {
    for (const goods of goodsList) {
      if (showFilterItem(goods, curFilterLabel, targetCouponInfo, goodsFilterLabels)) return true
    }
  }
  return false
}

/* --- 券筛选相关工具函数start --- */

/**
 * 获取可登陆筛选栏的券
 * @param {*} couponFilterLabelData 
 * @returns 
 */
export function getAvailableCoupon(couponFilterLabelData) {
  const { disabledCouponList = [], usableCouponList = [] } = couponFilterLabelData
  // 获取可用券中的多档券且存在未使用门槛的券
  const moreRulesUsableCoupon = usableCouponList?.filter(
    i => i.satisfied_range > 0 && i.satisfied_range < i.other_coupon_rule.length
  )
  const couponList = disabledCouponList?.concat(moreRulesUsableCoupon)
  if (!couponList?.length) return []

  return couponList
    // 1.满足一定限制的金额券、折扣券（wikiId = 1324178759）
    // 2.剔除购物车商品行匹配为空的券；3.剔除门槛差值为0的券
    .filter(c => [1, 5].includes(Number(c.coupon_type_id))
      && [1, 2, 3, 4, 7, 8].includes(Number(c.apply_for))
      && [1, 2].includes(Number(c.coupon_dimension))
      && c.mall_cart_coupon_goods_lists.length
      && getCouponDiff(c) !== '0'
    ) || []
}

/**
 * 从券助手数据中获取可作为筛选标签的券数据来源
 * @param {*} couponFilterLabelData 
 * @param {*} lang 
 * @returns 
 */
export function getCouponLabel(couponFilterLabelData, lang) {
  const couponList = getAvailableCoupon(couponFilterLabelData)
  return couponList
    // 整合券可用信息，多档券的优惠信息显示为最高档优惠
    .map(c => {
      const { id, other_coupon_rule, coupon, end_date, coupon_type_id } = c
      return {
        id,
        coupon,
        coupon_type_id,
        end_date,
        needPrice: getCouponDiff(c),
        discount: getDiscountByLevel(c, other_coupon_rule.length - 1)
      }
    })
    // 同样的券(折扣和门槛差值一样)，筛选出距离券过期时间较早的券
    .reduce((acc, current) => {
      const existing = acc.find(item => item.needPrice == current.needPrice && item.discount == current.discount)
      if (!existing || existing.end_date > current.end_date) {
        return acc.filter(item => item !== existing).concat(current)
      }
      return acc
    }, [])
    .sort((a, b) => a.needPrice - b.needPrice) // 按照门槛差值排序
    // 整合成券标签
    .map(item => {
      const { discount, id, coupon_type_id, coupon } = item
      return {
        type: 'coupon',
        orderKey: 'coupon',
        id,
        tip: template(discount, lang.SHEIN_KEY_PC_30134),
        couponType: coupon_type_id == '1' ? `coupon_money_${coupon}` : `coupon_off_${coupon}`, // 用于埋点
        iconName: filterLabelIcon['coupon']
      }
    })
}

/**
 * 判断当前选中券是否适用于指定商品行
 * @param {*} goodItem 购物车商品行信息
 * @param {*} targetCouponInfo 当前选中作为筛选条件的券
 * @returns 
 */
function goodsMatchCoupon(goodItem, targetCouponInfo) {
  const cartIds = targetCouponInfo?.mall_cart_coupon_goods_lists?.map(i => i?.cart_id) || []
  return cartIds.includes(goodItem.id)
}

/**
 * 计算满足下一档位门槛的差额
 * 下一档位: satisfied_range
 * satisfied_range为0，取couponItem.needPrice?.amount
 * 否则取对应档位need_price?.amount
 * @param {*} couponItem 
 * @param {*} symbol 金额类型，为true带货币信息
 * @returns 
 */
export function getCouponDiff(couponItem, symbol = false) {
  const { satisfied_range = '0', needPrice = {}, other_coupon_rule = [] } = couponItem
  if (satisfied_range == '0') {
    return symbol ? needPrice?.amountWithSymbol : needPrice?.amount
  } else {
    const targetRule = other_coupon_rule[satisfied_range] || {}
    return symbol
      ? targetRule?.need_price?.amountWithSymbol || '0'
      : targetRule?.need_price?.amount || '0'
  }
}

/**
 * 计算指定档位的优惠(折扣或满减)
 * coupon_type_id == '1' 为金额券，否则为折扣券
 * 金额券取指定档位的price?.priceSymbol
 * 折扣券需要拼接%
 */
export function getDiscountByLevel(couponItem, level) {
  const { coupon_type_id = '', other_coupon_rule = [] } = couponItem
  return coupon_type_id == '1'
    ? other_coupon_rule[level]?.price?.priceSymbol
    : (Number(other_coupon_rule[level]?.price?.price || 0) * 100 / 100) + '%'
}

/* --- 券筛选相关工具函数end --- */
