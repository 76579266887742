<template>
  <div
    v-if="isShow"
    :style="styles"
    class="incentive-reduce-price__wrap"
  >
    <sui_icon_decrease_12px
      :color="iconColor"
      size="12"
    />
    <span class="incentive-reduce-price__text">
      {{ text }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_decrease_12px } from '@shein-aidc/icon-vue3'

/**
 * 商品行降价利诱点组件
 */

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 文案
   * @type {String}
   */
  text: {
    type: String,
    default: '',
  },
  /**
   * 文案颜色
   */
  textColor: {
    type: String,
    default: '#FA6338',
  },
  /**
   * 图标颜色
   */
  iconColor: {
    type: String,
    default: '#FA6338',
  },
})

const styles = computed(() => ({
  '--text-color': props.textColor,
  '--icon-color': props.iconColor
}))
</script>

<style lang="less" scoped>
.incentive-reduce-price {
  &__wrap {
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    height: 20px;
    background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%), linear-gradient(90deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.30) 100.8%);
    overflow: hidden;
    color: var(--text-color);
  }
  &__text {
    margin-left: 2px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
