<script setup>
import { computed, ref, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import CheckboxTagItem from '../checkboxTag/CheckboxTagItem.vue'
import CartFilterCoupon from './CartFilterCoupon.vue'
import MoreFilter from './MoreFilter.vue'

const store = useStore()
const lang = computed(() => store?.state?.language)
const isRW = computed(() => store?.state?.locals?.IS_RW)
const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  modelValue: {
    type: [Boolean, Array, String],
    default: false
  },
  goodsFilterLabels: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:modelValue'])
const clickLabelVal = ref(null)
const checkValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

/* ---- 筛选栏吸顶 ---- */
const isFilterControlTop = computed(() => store?.getters?.isFilterControlTop)
/**
 * 筛选栏置顶逻辑:
 * 1. 进行筛选后，筛选栏常驻置顶，取消筛选 页面回到顶部，重新获取数据
 * 2. abt参数control_top为on，无论是否进行筛选都常驻置顶
 * 3. 滚动区域超过购物车列表，筛选栏和全场活动保持一致不吸顶
 * 4. 购物车列表数据较少，筛选栏和全场活动保持一致不吸顶
 * 1,2在当前vue文件中通过isSticky控制，3,4的滚动触发逻辑在public/src/pages/cart_new/index.js中
 */
const isSticky = computed(() => {
  return checkValue.value != 'none' || isFilterControlTop.value
})

let stickyTop = ref(0)
let shopPromoDom = null
let resizeObserver = null

const calcStickyTop = () => {
  if (resizeObserver) return
  shopPromoDom = document.querySelector('.j-top-tpl')
  if (!shopPromoDom) return
  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      stickyTop.value = Math.floor(entry?.contentRect?.height) || 0
    }
  })
  resizeObserver.observe(shopPromoDom)
}

/* ---- 券筛选 ---- */
const targetCouponInfo = computed(() => store?.state?.targetCouponInfo)
const showScanLights = ref(false)
const isScan = ref(false)
const couponSelectType = computed(() => store?.getters?.couponSelectType)

// 券筛选新人指引
const handleFilterCouponGuide  = async () => {
  if (couponSelectType.value == 'couponhelper') return // 未开启券筛选
  if (typeof window != 'undefined') {
    const isVisited = window.localStorage.getItem('filterCouponGuide')
    if (isVisited) return
    await nextTick()
    let labelDom = document.querySelectorAll('.j-coupon-label')
    if (!labelDom.length) return
    // 检测是否有券筛选在视口中
    let inView = []
    labelDom.forEach(element => {
      const rect = element.getBoundingClientRect()
      if (rect.left >= 0 && rect.left <= window.innerWidth) {
        inView.push(element)
      }
    })
    if (!inView.length) {
      // 如果视口中没有券，执行自动滚动露出一张券
      const firstCoupon = labelDom[0]
      if (!firstCoupon) return
      firstCoupon.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    // 为每一张券加扫光动画
    showScanLights.value = true
    // 在进行扫光动画时，筛选栏吸顶，3s后恢复原状态
    isScan.value = true
    setTimeout(() => {
      isScan.value = false
    }, 3000)
    window.localStorage.setItem('filterCouponGuide', true)
  }
}

/* ---- 筛选标签样式 ---- */
const filterControlStyle = computed(() => store?.getters?.filterControlStyle)
const getIconName = (label) => {
  const style = filterControlStyle?.value || 'default'
  const checked = checkValue.value == 'none'
    ? false
    : label.type == 'coupon'
      ? checkValue?.value == label.id
      : checkValue?.value == label.type
  const iconName = label?.iconName
  if (style == 'A') {
    return checked ? iconName?.aChecked : iconName?.a
  } else if (style == 'B') {
    return checked ? iconName?.bChecked : iconName?.b
  } else {
    return checked ? iconName?.defaultChecked : iconName?.default
  }
}

/* ---- 多标签收纳 ---- */
const moreFilterControl = computed(() => store?.getters?.moreFilterControl)
const filterType = computed(() => store?.state?.filterType)
const dynamicLabel = computed(() => store?.state?.dynamicLabel)
const hasMoreFilter = ref(true)
let filterSplitIndex = ref(props?.goodsFilterLabels.length)
let lastDynamicLabel = ref(null)
const labelsInFirstView = computed(() => props?.goodsFilterLabels.slice(0, filterSplitIndex.value))
const labelsInMorePop = computed(() => props?.goodsFilterLabels.slice(filterSplitIndex.value))

watch(filterType, (newVal, oldVal) => {
  analysis17326(newVal, oldVal)
  handleDynamicLabel(newVal)
  scrollToTarget()
})

// 将选中的标签自动滚动至视口中心
const scrollToTarget = async () => {
  await nextTick()
  const targetLabel = filterType.value
  const containerDom = document.querySelector('.j-filter-row')
  if (!containerDom) return
  // 如果选中的是all，滚动至最左侧
  if (targetLabel == 'none') {
    containerDom.scrollTo({ left: 0, behavior: 'smooth' })
    return
  }
  // 获取选中标签的索引
  const targetIndex = labelsInFirstView.value?.findIndex(
    l => l.type == 'coupon' ? l.id == targetLabel : l.type == targetLabel
  )
  if (targetIndex < 0) return
  const labelDom = document.querySelectorAll('.j-label-item')
  const targetDom = labelDom[targetIndex]
  // 获取标签容器和当前标签的位置信息
  const containerRect = containerDom.getBoundingClientRect()
  const targetRect = targetDom.getBoundingClientRect()
  // 计算滚动位置
  let scrollLeft = targetDom.offsetLeft - (containerRect.width - targetRect.width) / 2
  // 如果没有足够的滚动空间，滚动至最右边
  if (scrollLeft + targetDom.offsetWidth > containerDom.scrollWidth) {
    scrollLeft = containerDom.scrollWidth - containerDom.offsetWidth
  }
  // 滚动至计算出的位置
  containerDom.scrollTo({ left: scrollLeft, behavior: 'smooth' })
}

// 初始化筛选标签
const initLabels = async () => {
  if (moreFilterControl.value === 'off') return
  // 开启了多标签收纳功能后，将标签分为两组（平铺+收纳）
  await nextTick()
  const index = findFirstIndexOutOfView()
  if (index > -1) {
    filterSplitIndex.value = index
    hasMoreFilter.value = true
  } else {
    hasMoreFilter.value = false
  }
}

// 获取第一个不在视口中的筛选标签索引，此索引值即为首屏可显示的筛选标签个数
const findFirstIndexOutOfView = () => {
  const labels = document.querySelectorAll('.j-label-item')
  const containerDom = document.querySelector('.j-filter-row')
  if(!containerDom) return -1
  const containerRect = containerDom.getBoundingClientRect()
  for (let i = 0; i < labels.length; i++) {
    const rect = labels[i].getBoundingClientRect()
    if (rect.left >= containerRect.right) {
      return i
    }
  }
  return -1
}

// 处理机动位
const handleDynamicLabel = (newVal) => {
  if (newVal === 'none') return

  // 选中收纳器里的标签，更新机动位，选中平铺的标签，不更新机动位
  const targetInMorePop = labelsInMorePop.value.find(l => !isNaN(filterType.value)
    ? filterType.value === l.id
    : filterType.value === l.type
  )
  if (targetInMorePop) {
    // 记录上次的机动位数据，用于切换动画
    if (dynamicLabel.value && JSON.stringify(targetInMorePop) !== JSON.stringify(dynamicLabel.value)) {
      lastDynamicLabel.value = dynamicLabel.value
    }
    updateDynamicLabel(targetInMorePop)
  }
}

// 更新机动位数据
const updateDynamicLabel = async(val) => {
  store.commit('updateField', { key: 'dynamicLabel', value: val })
  // 如果选中机动位，自动滚动至最右侧
  if (val && val.type) {
    await nextTick()
    const containerDom = document.querySelector('.j-filter-row')
    containerDom && (containerDom.scrollTo({ left: containerDom?.offsetWidth, behavior: 'smooth' }))
    if (lastDynamicLabel.value) {
      let timeoutId = setTimeout(() => {
        lastDynamicLabel.value = null
        clearTimeout(timeoutId)
      }, 250)
    }
  }
}

// const animationEnd = () => {
//   const dynamicDom = document.querySelector('.dynamic-label')
//   dynamicDom && dynamicDom.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
//   const containerDom = document.querySelector('.j-filter-row')
//   containerDom && containerDom.scrollTo({ left: containerDom?.offsetWidth, behavior: 'smooth' })
// }

const updateCheckValue = (val) => {
  checkValue.value = val
}

const analysis17325 = (labels, show_type) => {
  const label_id = labels.map((v) => v.type === 'coupon' ? v.couponType : v.type).join(',')
  daEventCenter.triggerNotice({
    daId: '1-7-3-25',
    extraData: {
      label_id,
      show_type,
    }
  })
}

const setClickLabelVal = (val) => {
  clickLabelVal.value = val
}

const analysis17326 = (newVal, oldVal) => {
  if (clickLabelVal.value === 'none') return // 点击all标签不上报

  const labelVal = newVal === 'none' ? oldVal : newVal
  const is_cancel = newVal === 'none' ? 0 : 1
  const label_id = !isNaN(labelVal)
    ? props.goodsFilterLabels.find(i => labelVal == i.id)?.couponType
    : labelVal
  
  const inFirstView = labelsInFirstView.value
    .find(l => l.type === 'coupon' ? l.id === labelVal : l.type === labelVal)

  const saParams = {
    label_id,
    is_cancel,
    show_type: inFirstView ? 'default_outside' : 'filter_menu_inside'
  }
  daEventCenter.triggerNotice({
    daId: '1-7-3-26',
    extraData: saParams
  })
}

onMounted(async () => {
  calcStickyTop()
  await initLabels()
  handleFilterCouponGuide()
  analysis17325(labelsInFirstView.value, 'default_outside')
})

onBeforeUnmount(() => {
  resizeObserver?.unobserve(shopPromoDom)
  resizeObserver = null
  showScanLights.value = false
  isScan.value = false
})
</script>

<template>
  <div
    class="cart-list-filter j-cart-list-filter"
    :class="[{ 'filter-sticky': isSticky || isScan}, isRW ? 'rw-filter' : 'sh-filter']"
    :style="{ 'top': isSticky || isScan ? (stickyTop ? (stickyTop - 1) : 0) + 'px' : 0 }"
    :data-top="stickyTop ? (stickyTop - 1) : 0"
  >
    <div class="filter-bar">
      <!-- 筛选标签平铺区域 -->
      <div class="filter-row j-filter-row">
        <!-- all标签 -->
        <CheckboxTagItem
          v-if="goodsFilterLabels.length >= 1"
          v-model="checkValue"
          label="none"
          :show-close="false"
          :style-config="filterControlStyle"
          @click-label-val="setClickLabelVal"
        >
          <span class="filter-label">{{ lang.SHEIN_KEY_PC_30907 }}</span>
        </CheckboxTagItem>
        <!-- 平铺筛选标签 -->
        <CheckboxTagItem
          v-for="(labelItem, index) in labelsInFirstView"
          :key="labelItem.type + index"
          v-model="checkValue"
          class="j-label-item"
          :label="labelItem.type == 'coupon' ? labelItem.id : labelItem.type"
          :style-config="filterControlStyle"
          :class="{
            'j-coupon-label': labelItem.type == 'coupon',
            'scan-lights': labelItem.type == 'coupon' && showScanLights
          }"
          @click-label-val="setClickLabelVal"
        >
          <div class="filter-label">
            <Icon
              size="12px"
              class="filter-label-icon"
              :class="labelItem.type"
              :is-responsive-name="true"
              :name="getIconName(labelItem)"
            />
            <span class="label-text">{{ labelItem.tip }}</span>
          </div>
        </CheckboxTagItem>
        <!-- 多标签收纳情况下的外围机动坑位 -->
        <transition name="slide-fade">
          <CheckboxTagItem
            v-if="dynamicLabel"
            v-model="checkValue"
            class="dynamic-label"   
            :label="dynamicLabel?.type == 'coupon' ? dynamicLabel?.id : dynamicLabel?.type"
            :style-config="filterControlStyle"
            @click-label-val="setClickLabelVal"
          >
            <div class="filter-label">
              <Icon
                name="sui_icon_filter_16px"
                size="12px"
                :is-rotate="GB_cssRight"
              />
              <i class="line"></i>
              <!-- 机动位数据变化时执行动画的dom -->
              <div
                v-if="lastDynamicLabel" 
                class="two-lines-label"
              >
                <span class="label-text">{{ lastDynamicLabel?.tip }}</span>
                <span class="label-text">{{ dynamicLabel?.tip }}</span>
              </div>
              <!-- 机动位从无到有或更新数据动画结束后的dom -->
              <span
                v-else
                class="label-text"
              >{{ dynamicLabel?.tip }}</span>
            </div>
          </CheckboxTagItem>
        </transition>
      </div>
      <!-- 多筛选标签收纳 -->
      <MoreFilter
        v-if="['logo', 'text'].includes(moreFilterControl) && hasMoreFilter"
        :labels-in-more-pop="labelsInMorePop"
        :model-value="checkValue"
        @update:model-value="updateCheckValue"
        @analysis17325="analysis17325"
      />
    </div>
    <CartFilterCoupon v-if="targetCouponInfo?.id" />
  </div>
  <div
    class="j-filter-placeholder"
    style="display: none"
  ></div>
</template>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.cart-list-filter {
  width: 100%;
  // padding: 10px 0;
  background: #F4F4F4;
  margin-bottom: -10px;
  .filter-label {
    height: 100%;
    display: inline-flex;
    align-items: center;
    .label-text {
      max-width: 136px;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 26px;
      line-height: 26px;
    }
    .line {
      width: 1px;
      height: 10px;
      background: #999;
      margin: 0 3px;
    }
  }
  .cart-checkbox-tag {
    margin-right: 8px;
  }
  .filter-label-icon {
    width: 12px;
    display: inline-block;
    margin-right: 2px;
    &.flash_sale {
      color: @sui_color_flash!important; /* stylelint-disable-line declaration-no-important */
      /*rw:begin*/
      color: @sui_color_promo!important; /* stylelint-disable-line declaration-no-important */
    }
    &.almost_out_of_stock {
      color: @sui_color_highlight!important; /* stylelint-disable-line declaration-no-important */
      /*rw:begin*/
      color: @sui_color_discount!important; /* stylelint-disable-line declaration-no-important */
    }
    &.markdowns,
    &.discount_limit,
    &.limit_off,
    &.under_price,
    &[class*='5star'],
    &[class*='30day_sale'],
    &[class*='30day_additem'] {
      color: #C44A01!important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .filter-bar {
    width: 100%;
    display: inline-flex;
    align-items: center;
    .filter-row {
      display: inline-flex;
      flex: 1;
      align-items: center;
      padding: 10px 8px 10px 0;
      white-space: nowrap;
      overflow-x: auto;
      /* 隐藏滚动条，当IE下溢出，仍然可以滚动 */
      -ms-overflow-style: none;
      /* 火狐下隐藏滚动条 */
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
      /* Chrome下隐藏滚动条，溢出可以透明滚动 */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  
  .coupon-select {
    width: 100%;
  }
}
@media (min-width: 800px){
  .sh-filter.filter-sticky {
    position: sticky;
    z-index: @zindex-out;
    margin-bottom: 0;
  }
}
@media (min-width: 768px){
  .rw-filter.filter-sticky {
    position: sticky;
    z-index: @zindex-out;
    margin-bottom: 0;
  }
}
.style-a {
  &.cart-checkbox-tag_checked {
    .line {
      background: rgba(250, 99, 56, 0.60);
    }
  }
}
.style-b {
  &.cart-checkbox-tag_checked {
    .filter-label-icon {
      color: #FFF!important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
// 扫光动画
.scan-lights {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 230, 167, 0), rgba(255, 230, 167, 1), rgba(255, 230, 167, 0));
    animation: ScanLights 1.5s linear 2 forwards;
  }
}
@keyframes ScanLights {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
// 多标签收纳机动位动画
// .dynamic-label {
//   flex: none;
//   min-width: unset;
//   max-width: 200px;
// }
// .slide-fade-enter-active,
// .slide-fade-leave-active {
//   transition: max-width 8s ease-out;
//   // transition: max-width 0.25s ease-out;
// }
// .slide-fade-enter-from,
// .slide-fade-leave-to {
//   max-width: 27px;
// }

.two-lines-label {
  animation: slide 250ms linear;
  transform: translateY(-14px);
  .label-text {
    display: block;
    line-height: 28px;
  }
}

@keyframes slide {
  0% {
    transform: translateY(14px);
  }
  100% {
    transform: translateY(-14px);
  }
}
</style>
