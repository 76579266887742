<template>
  <div
    class="empty-cart-container"
  >
    <EmptyUnPaidOrder
      v-if="CartUnpaidOrder"
      v-show="showUnPaidOrder"
      :order="unPaidOrderInfo.orderData"
      :language="language"
      @update-order="handleUpdateOrder"
      @jump-to-order="handleViewOrder"
    />
    <ClientOnly>
      <EmptyCancelOrder
        v-show="showCancelOrderTip && isRealShow"
        :language="language"
        :is-real-show="isRealShow"
      />
    </ClientOnly>
    <sh-empty-icon />
    <p class="empty-tip bold">
      {{ (language.SHEIN_KEY_PC_14562 || '').toUpperCase() }}
    </p>
    <div
      v-if="!isLogin"
      class="empty-cart-nologin"
    >
      <slot name="unlogin">
        <p class="unlogin-tip">
          {{ (language.SHEIN_KEY_PC_18187 || '') }}
        </p>
        <s-button-group
          class="mt-40"
          :width="isClient ? '' : 240"
        >
          <s-button-group-item
            :type="['primary', 'H44PX']"
            da-event-click="1-7-1-40"
            class="empty-cart-btn"
            @click="handleToSign"
          >
            {{ (language.SHEIN_KEY_PC_15740 || '').toUpperCase() }}
          </s-button-group-item>
          <s-button-group-item 
            v-expose="{
              id: '1-7-1-15',
              data: {
                go_to_page: goToPageType
              }
            }"
            v-tap="{
              id: '1-7-1-14',
              data: {
                go_to_page: goToPageType
              }
            }" 
            :type="['default', 'H44PX']"
            class="empty-cart-btn"
            @click="handleToShop"
          >
            {{ language.SHEIN_KEY_PC_16337 }}
          </s-button-group-item>
        </s-button-group>
      </slot>
    </div>
    <div
      v-else
      class="emprt-cart-operate"
    >
      <s-button
        v-expose="{
          id: '1-7-1-15',
          data: {
            go_to_page: goToPageType
          }
        }" 
        v-tap="{
          id: '1-7-1-14',
          data: {
            go_to_page: goToPageType
          }
        }"
        :type="['primary', 'H44PX']"
        class="empty-cart-btn"
        @click="handleToShop"
      >
        {{ language.SHEIN_KEY_PC_16337 }}
      </s-button>
    </div>
  </div>
</template>

<script>
import { SButton } from '@shein-aidc/sui-button'
import { SButtonGroup } from '@shein-aidc/sui-button-group'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { queryLatestUnPaidOrderInfo } from '../../utils/fetcher'
import { mapState, mapMutations } from 'vuex'
const { langPath, forceHttp } = gbCommonInfo
import { stringifyQueryString } from '@shein/common-function'
import ShEmptyIcon from './ShEmptyIcon.vue'
import EmptyUnPaidOrder from '../unPaidOrderTip/emptyUnPaidOrder.vue'
import EmptyCancelOrder from '../cancelOrderTip/EmptyCancelOrder.vue'


export default {
  name: 'EmptyCartVue',
  components: {
    EmptyCancelOrder,
    SButton: SButton,
    SButtonGroup: SButtonGroup,
    SButtonGroupItem: SButtonGroupItem,
    ShEmptyIcon,
    EmptyUnPaidOrder,
  },
  props: {
    isRealShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['language', 'loginStatus', 'unPaidOrderInfo', 'ABTParams', 'cartInfo']),
    isClient () {
      return typeof window !== 'undefined'
    },
    isLogin(){
      return this.loginStatus
    },
    goToPageType () {
      return 'shopnow'
    },
    showUnPaidOrder() {
      return this.loginStatus && this.unPaidOrderInfo?.orderData?.billno
    },
    CartUnpaidOrder() {
      return this.ABTParams?.CartUnpaidOrder?.param?.cart_unpaidOrder == 'on'
    },
    showCancelOrderTip() {
      return this.loginStatus && this.cartInfo.cartSumQuantity == 0 && this.ABTParams?.CancelledorderTip?.param?.cartcancelled_tip == 1 && !(this.CartUnpaidOrder && this.showUnPaidOrder)
    }
  },
  methods: {
    ...mapMutations(['updateField']),
    handleToSign() {
      location.href = `${langPath}/user/auth/login?redirection=` + encodeURIComponent('/cart')
    },
    handleToShop() {
      location.href = forceHttp + transformPromodiscountUrl(`/shein-picks.html`)
    },
    async handleUpdateOrder() { // 未支付订单 客户端渲染兜底 pageId=1350582498
      let data = await queryLatestUnPaidOrderInfo()
      if(data?.orderData?.billno) {
        this.updateField({ key: 'unPaidOrderInfo', value: data })
      }
    },
    handleViewOrder(billno, queryParams) {
      let url = `/checkout/checkout_again/${billno}?${stringifyQueryString({ queryObj: queryParams })}`
      window.open(`${location.origin}${url}`)
    },
  }
}
</script>

<style lang="less">
.empty-cart-container {
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty-cart-btn {
    min-width: 270px;
    height: 42px;
    line-height: 40px;
  }
  .emprt-cart-operate {
    margin-top: 30px;
    padding-bottom: 50px;
    .flexbox();
    .align-center();
    flex-direction: column;
  }
  .empty-tip {
    &.bold {
      font-size: 18px;
      font-weight: 800;
      color: #060606;
    }
  }
  .unlogin-tip {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
    font-weight: 400;
  }

  .mt-40 {
    margin-top: 40px;
  }
  .empty-cancel-order {
    margin-bottom: -26px;
    margin-top: 50px;
  }
}
</style>
