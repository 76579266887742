import { template, isObject } from '@shein/common-function'
import formatPercent from 'public/src/pages/common/promotion/utils/common.js'

const { percentNum, percentNumWithoutSign } = formatPercent

const ADDON_TYPE_MAP =  {
  'discount': {
    headerRightComponentName: 'DiscountPrice',
  },
  'reduce': {
    headerRightComponentName: 'ReducePrice',
  },
  'returnCoupon': {
    headerComponentName: 'ReturnCouponHead',
  },
}

const getPromotionProgress = (promotion, isEveryFull, isFullMaxDiscount) => {
  const { promotion_range_info, rules } = promotion
  const { range, next } = promotion_range_info || {}
  
  const calcRangeProcess = (rule, preRule, ruleIndex, rangeInfo) => {
    const { range, next, diff = {} } = rangeInfo || {}
    if (next == 0) { // 该活动所有档位凑满
      return 100
    }
    if (ruleIndex == range) {
      const { value } = rule 
      const preValue = preRule?.value || 0
      const x = (value - diff?.value || 0)
      return (x - preValue) / (value - preValue) * 100
      // (当前金额 - 上一档门槛) / (当前档门槛 - 上一档门槛) * 100
    }
    return range > ruleIndex ? 100 : 0
  }

  if (isEveryFull) {
    const { diff } = promotion_range_info || {}
    const { value } = rules?.[0] || {}
    return [
      {
        progressRate: isFullMaxDiscount ? 100 : (value - diff.value) / value * 100, // 凑单进度
        ruleIndex: 0, // 当前档位
        activeRuleIndex: 0, // 正在凑单档位
        isFullMaxDiscount,
      }
    ]
  }
  if (!Array.isArray(rules)) return []
  return rules.map((rule, index) => {
    return {
      progressRate: calcRangeProcess(rule, rules[index - 1], index, promotion_range_info), // 凑单进度
      ruleIndex: index, // 当前档位
      activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
    }
  })
}

const getAccessoryGiftInfo = (promotion, isPicked) => {
  const { promotion_range_info, rules } = promotion
  const { range } = promotion_range_info
  const isSoldOut = (item) => {
    return [2, 3].includes(+item.on_sale_status) || item.stock_status == '2'
  }

  let canPickProducts = [] // 可选的附属品
  let showPickProducts = [] // 展示的附属品
  let showPickProductsTotal = 0 // 展示的附属品总数 

  if (range == 0) {
    showPickProducts = rules?.[0]?.list?.filter(item => !isSoldOut(item))
    showPickProductsTotal = rules?.[0]?.total
  } else {
    showPickProducts = rules?.[range - 1]?.list?.filter(item => !isSoldOut(item))
    canPickProducts = rules?.slice(0, range).reverse().reduce((acc, cur) => {
      const list = cur?.list?.filter(item => !isSoldOut(item))
      return acc.concat(list)
    }, [])
  }
  return {
    showPickProducts,
    showPickProductsTotal,
    needShowRetainDialog: !isPicked && canPickProducts.length > 0 // 展示挽留弹窗
  }
}

/**
 * @description: 处理AddOnV2凑单弹窗组件的props数据
 * @param {*} promo 促销详情数据
 * @param {*} language 多语言数据
 * @param {*} options 额外的其他参数坑位
 * @return {*}
 */
export function getAddOnPropData(promo, language = {}, options = {}) {
  // 促销类型分类(按UI组件类型)
  let addOnType = ''
  if([1, 9, 25, 26].includes(Number(promo.type_id || 0))) addOnType = 'discount'
  else if(promo.type_id == 14) addOnType = 'reduce'
  else if ([4, 28, 2, 22, 13].includes(+promo.type_id)) addOnType = 'accessory'
  else if (promo.type_id == 15) addOnType = 'bundle'
  else if ([20, 21].includes(+promo.type_id)) addOnType = 'returnCoupon'

  // 按UI组件类型分类输出add-on-dialog弹窗组件的props数据
  if(['discount', 'reduce', 'bundle'].includes(addOnType)) {
    const range = promo?.promotion_range_info?.range
    const { rules = [], type_id } = promo
    // 是否是每满的活动
    const isEveryFull =  type_id == 1 || (type_id == 15 && rules[0].type == 2) || (type_id == 14 && (rules[0].type == 2 || rules[0].type == 4))
    // 是否已满足最大优惠限制（暂时只有type_id=14才有）
    let isFullMaxDiscount = false
    if (type_id == 14) {
      const { hit_range } = promo?.promotion_range_info || {}
      const { max_discount } = rules?.[0] || {}
      isFullMaxDiscount = max_discount > 0 && hit_range?.total_discount_amount?.amount >= max_discount
    }

    let isSatisfied = 0
    if(range > 0 && range < rules.length) isSatisfied = 2
    else if(range >= rules.length) isSatisfied = 1
    if(range > 0 && isEveryFull) {
      if (isFullMaxDiscount) {
        isSatisfied = 1
      } else {
        isSatisfied = 2
      }
    } // loop rules special handle
    const diffAmount = promo?.promotion_range_info?.diff?.value_amount
    let goodsPrice = ''
    if(diffAmount) goodsPrice = ((diffAmount?.usdAmount || 0) * 100).toFixed(0)

    const texts =  getTipTexts(promo, language)
    

    return {
      addOnType,
      headerRightComponentName: ADDON_TYPE_MAP[addOnType]?.headerRightComponentName || '', // 指定右侧挂件组件名称
      promoDetail: promo, // 原始促销数据
      promotion_id: promo.promotion_id,
      title: texts?.topText, // 弹窗头部文案
      tip: texts?.processText || promo?.content?.tip || '', // 弹窗footer文案
      showCountDown: promo?.is_count_down == 1,
      countDownTimeStamp: promo?.end_time,
      progressData: getPromotionProgress(promo, isEveryFull, isFullMaxDiscount),
      mallCode: promo.mall_code,
      range: promo?.promotion_range_info?.range,
      includeTspId: promo?.lable_id,
      goodsPrice,
      isSatisfied,
      progressComponentName: isEveryFull ? 'EveryFullProgress' : '',
      earnedBenefitsText: texts?.earnedBenefitsText || '', // 已享受权益文案
      footerTopComponentName: texts?.earnedBenefitsText ? 'EarnedBenefits' : ''
    }
  } else if (addOnType === 'accessory') {
    const { isChecked, isPicked } = options
    const { range, next, diff } = promo?.promotion_range_info
    return {
      addOnType,
      progressData: getPromotionProgress(promo),
      subHeaderComponentName: 'AccessorySubHeader',
      retainDialogComponentName: 'AccessoryRetain',
      promoDetail: promo, // 原始促销数据
      promotion_id: promo.promotion_id,
      title: [4, 2, 28].includes(+promo.type_id) ? language.SHEIN_KEY_PC_28533 : language.SHEIN_KEY_PC_28534,
      tip: isObject(promo?.content?.tip) ? promo?.content?.tip[isChecked ? 1 : 0] : promo?.content?.tip,
      showCountDown: promo?.is_count_down == 1,
      countDownTimeStamp: promo?.end_time,
      mallCode: promo.mall_code,
      range: range,
      isSatisfied: next == 0 ? 1 : (range > 0 ? 2 : 0),
      goodsPrice: diff?.value_amount?.usdAmount || 0,
      includeTspId: promo?.lable_id,
      ...getAccessoryGiftInfo(promo, isPicked),
    }
  } else if (addOnType === 'returnCoupon') {
    const { range, next, diff } = promo?.promotion_range_info
    return {
      addOnType,
      headerComponentName: ADDON_TYPE_MAP[addOnType]?.headerComponentName,
      promoDetail: promo, // 原始促销数据
      promotion_id: promo.promotion_id,
      showCountDown: promo?.is_count_down == 1,
      countDownTimeStamp: promo?.end_time,
      title: promo.coupon_num > 1 ? language.SHEIN_KEY_PC_27690 : language.SHEIN_KEY_PC_27689,
      tip: promo?.content?.tip || '',
      progressData: getPromotionProgress(promo),
      mallCode: promo.mall_code,
      range,
      isSatisfied: next == 0 ? 1 : (range > 0 ? 2 : 0),
      goodsPrice: diff?.value_amount?.usdAmount || 0,
      includeTspId: promo?.lable_id,
    }
  } else {
    return {}
  }
}

export function getTipTexts(promo, language = {}) {
  const { typeId, rules } = promo
  const { range, diff, hit_range } = promo?.promotion_range_info || {}
  if(typeId == 14) { // 全场满减和部分满减文案相同
    const type = rules[0].type
    // 是否每满活动
    const isEveryFull = type == 2 || type == 4
    const maxDiscount = rules?.[rules?.length - 1]?.discount?.value_amount?.amountWithSymbol
    const nextRule = isEveryFull ? rules[0] : rules[range] // 下一档, 凑单档位
    const rule = range > 0 && !isEveryFull ? rules[range - 1] : rules[0] // 以满足的档位

    // 底部已享受权益文案
    let earnedBenefitsText = ''
    if (isEveryFull) {
      if (hit_range?.total_discount_amount?.amount > 0) {
        earnedBenefitsText = template(
          `<em>${ hit_range?.total_discount_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_28496
        )
      }
    }

    // 进度条文案
    let processText = ''
    // 进度条增长未使档次提升气泡提示
    let closeBubbleText = template(
      `<em>${ diff?.value }</em>`,
      `<em>${ nextRule?.discount?.value_amount?.amountWithSymbol }</em>`,
      language.SHEIN_KEY_PC_28570
    )
    // 进度条增长使档次提升气泡提示
    let reachBubbleText = template(
      `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
      language.SHEIN_KEY_PC_28577
    )
    if (type == 2) { // 每满X件
      // 是否已满足最大优惠限制
      const isFullMaxDiscount = rule?.max_discount > 0 && hit_range?.total_discount_amount?.amount >= rule?.max_discount
      if (range <= 0) {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_29627
        )
      } else if (isFullMaxDiscount) {
        processText = template(
          language.SHEIN_KEY_PC_29630
        )
      } else if (diff?.value == rule?.value) {
        processText = template(
          `<em>${ hit_range?.total_discount_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_29628
        )
      } else if (diff?.value < rule?.value) {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_29629
        )
      }

      closeBubbleText = template(
        `<em>${ diff?.value }</em>`,
        language.SHEIN_KEY_PC_29632
      )
      reachBubbleText = template(
        language.SHEIN_KEY_PC_29633
      )
    } else if (type == 4) { // 每满X元
      // 是否已满足最大优惠限制
      const isFullMaxDiscount = rule?.max_discount > 0 && hit_range?.total_discount_amount?.amount >= rule?.max_discount
      if (range <= 0) {
        processText = template(
          `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_29627
        )
      } else if (isFullMaxDiscount) {
        processText = template(
          language.SHEIN_KEY_PC_29630
        )
      } else {
        processText = template(
          `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PC_29629
        )
      }

      closeBubbleText = template(
        `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
        language.SHEIN_KEY_PC_29632
      )
      reachBubbleText = template(
        language.SHEIN_KEY_PC_29633
      )
    }
    return {
      topText: template(maxDiscount, language.SHEIN_KEY_PC_28565),
      closeBubbleText,
      reachBubbleText,
      earnedBenefitsText,
      processText,
    }
  } else if (typeId == 26) { // 全场满折
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价打折
      return {
        topText: template(
          maxDiscount,
          language.SHEIN_KEY_PC_28567.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28572.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28579.replace('%', '')
        ),
      }
    } else { // 按原价打折
      return {
        topText: template(
          maxDiscount,
          language.SHEIN_KEY_PC_28566.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28571.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28578.replace('%', '')
        ),
      }
    }
  } else if (typeId == 25) { // 满折
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价折扣
      return {
        topText: template(
          maxDiscount, 
          language.SHEIN_KEY_PC_28567.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28576.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28579.replace('%', '')
        ),
      } 
    } else { // 按原价打折
      return {
        topText: template(
          maxDiscount, 
          language.SHEIN_KEY_PC_28566.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28575.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PC_28578.replace('%', '')
        ),
      }
    }
  } else if (typeId == 9) { // 买N折N
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxRange = rules?.[rules?.length - 1]
    const maxThreshold = maxRange?.value
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价打折
      return {
        topText: template(
          maxThreshold, 
          ((1 - (maxRange?.discount?.value || 0)) * 100).toFixed(0), 
          language.SHEIN_KEY_PC_28569
        ),
        closeBubbleText: template(
          `<em>${ ((1 - nextRule?.discount?.value) * 100).toFixed(0) }</em>`,
          language.SHEIN_KEY_PC_28574.replace('%', ''),
        ),
        reachBubbleText: template(
          `<em>${ ((1 - rule?.discount?.value) * 100).toFixed(0) }</em>`,
          language.SHEIN_KEY_PC_28579.replace('%', ''),
        ),
      }
    } else { // 按原价打折
      if(['hk', 'tw'].includes(gbCommonInfo?.lang)) {
        return {
          topText: template(
            maxThreshold, 
            Number((maxRange?.discount?.value * 10).toFixed(1)), 
            language.SHEIN_KEY_PC_28568
          ),
          closeBubbleText: template(
            `<em>${ Number((nextRule?.discount?.value * 10).toFixed(1)) }%</em>`,
            language.SHEIN_KEY_PC_28573.replace('%', ''),
          ),
          reachBubbleText: template(
            `<em>${ Number((rule?.discount?.value * 10).toFixed(1)) }%</em>`,
            language.SHEIN_KEY_PC_28578.replace('%', ''),
          ),
        }
      }
      return {
        topText: template(
          maxThreshold, 
          ((1 - maxRange?.discount?.value) * 100).toFixed(0) + '%', 
          language.SHEIN_KEY_PC_28568.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ ((1 - nextRule?.discount?.value) * 100).toFixed(0) }%</em>`,
          language.SHEIN_KEY_PC_28573.replace('%', ''),
        ),
        reachBubbleText: template(
          `<em>${ ((1 - rule?.discount?.value) * 100).toFixed(0) }%</em>`,
          language.SHEIN_KEY_PC_28578.replace('%', ''),
        ),
      }
    }
  } else if (typeId == 1) {
    const { discount, value } = rules[0]
    let processText = ''
    const hitRangeNum = hit_range?.hit_range_num || 0 // 已享受活动的件数
    if(range <= 0) {
      if(discount.type == '4') { // 按特价
        processText = template(
          `<em>${ value }</em>`, // 门槛
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`, // 折扣
          `<em>${ diff?.value }</em>`, // 差额
          language.SHEIN_KEY_PC_28698.replaceAll('%', '')
        )
      } else {
        processText = template(
          `<em>${ value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          `<em>${ diff?.value }</em>`,
          language.SHEIN_KEY_PC_28695.replaceAll('%', '')
        )
      }
    } else if(diff?.value == value) {
      if(discount.type == '4') {
        processText = template(
          language.SHEIN_KEY_PC_28777.replaceAll('%', '')
        )
      } else {
        processText = template(
          language.SHEIN_KEY_PC_28777.replaceAll('%', '')
        )
      }
    } else if(diff?.value <= value) {
      if(discount.type == '4') {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          language.SHEIN_KEY_PC_28697.replaceAll('%', '')
        )
      } else {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          language.SHEIN_KEY_PC_28697.replaceAll('%', '')
        )
      }
    }
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    return {
      topText: template(
        maxDiscount,
        language.SHEIN_KEY_PC_28694.replace('%', '')
      ),
      earnedBenefitsText: hitRangeNum > 0 ? template(
        `<em>${ hitRangeNum }</em>`,
        `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
        language.SHEIN_KEY_PC_28696.replace('%', '')
      ) : '', // 权益文案
      closeBubbleText: template(
        `<em>${ diff.value }</em>`,
        language.SHEIN_KEY_PC_28700.replace('%', '')
      ), // 动效4, 接近门槛文案
      reachBubbleText: template(
        `<em>${ hitRangeNum }</em>`,
        `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
        language.SHEIN_KEY_PC_28701.replace('%', '')
      ), // 动效5 已达门槛文案
      processText, // 进度条文案
    }
  } else if (typeId == 15) {
    // x元n件
    const maxRange = rules?.length ? rules.length - 1 : 0 // 最高档
    const maxBundlePrice = rules?.[maxRange]?.discount?.value_amount?.amountWithSymbol // 最高挡打包购买的金额
    const maxItemsPerBundle = rules?.[maxRange]?.value // 最高档的件数门槛
    const hitRangeNum = hit_range?.hit_range_num || 0 // 已享受活动的件数
    const hitRangePrice = hit_range?.total_discount_amount?.amountWithSymbol || 0 // 已享受活动的总价格
    const { type, discount, value } = rules[0]
    let processText = ''

    // 文案集合
    const textObjList = rules.map(item => {
      // 满x件
      if (item.type == 1 && item.discount?.type == 7) {
        return {
          topText: template(
            maxItemsPerBundle,
            maxBundlePrice,
            language.SHEIN_KEY_PC_28702
          ),

        }
      // 每满X件
      } else if (item.type == 2 && item.discount?.type == 7) {
        return {
          topText: template(
            maxBundlePrice,
            maxItemsPerBundle,
            language.SHEIN_KEY_PC_28703
          ),
          earnedBenefitsText: hitRangeNum > 0 ? template(
            `<strong>${hitRangeNum}</strong>`,
            `<strong>${hitRangePrice}</strong>`,
            language.SHEIN_KEY_PC_28707
          ) : ''
        }
      }
    })

    // 每满x件
    if (type == 2) {
      if(range <= 0) {
        processText = template(
          `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
          `<em>${value}</em>`,
          `<em>${diff?.value}</em>`,
          language.SHEIN_KEY_PC_28704
        )
      } else if(diff?.value == value) {
        processText = template(
          language.SHEIN_KEY_PC_28777
        )
      } else if(diff?.value <= value) {
        processText = template(
          `<em>${diff?.value}</em>`,
          language.SHEIN_KEY_PC_28706
        )
      }
    }

    return {
      // 顶部title
      topText: textObjList[maxRange]?.topText || '',
      // 底部已享受权益文案
      earnedBenefitsText: textObjList[0].earnedBenefitsText || '',
      // 进度条增长未使档次提升气泡提示
      closeBubbleText: template(
        `<strong>${ diff.value }</strong>`,
        language.SHEIN_KEY_PC_28700
      ),
      // 进度条增长使档次提升气泡提示
      reachBubbleText: template(
        `<strong>${ hitRangePrice }</strong>`,
        language.SHEIN_KEY_PC_28708
      ),
      processText,
    }
  } else return {}
}
