<template>
  <div
    v-show="negativeInfoObj.hasNegativeInfo"
    class="cart-negative-info"
  >
    <sui_icon_info_16px_1 size="16px" />
    <span>{{ language.SHEIN_KEY_PC_25794 }}</span>
    <span
      class="negative-view"
      @click="handleNegativeInfoShow"
    >
      {{ language.SHEIN_KEY_PC_25795 }}
    </span>
    <!-- 负向信息弹窗 -->
    <s-dialog
      v-model:visible="showNegativeInfos"
      :show-close="true"
      :append-to-body="true"
    >
      <template #title>
        <span class="negative-dialog-title">{{ language.SHEIN_KEY_PC_25796 }}</span>
      </template>
      <div class="cart-item__negative-list">
        <!-- 不参与积分活动的商品 -->
        <div 
          v-if="negativeInfoObj.disablePoints.length > 0"
          class="cart-item__disable-point"
        >
          <div class="negative-swiper-title">
            <Icon
              name="sui_icon_info_16px_1"
              size="16px"
            />
            {{ language.SHEIN_KEY_PC_22343 }}
          </div>
          <CartItemSwiper
            v-if="renderSwiper"
            :items="negativeInfoObj.disablePoints"
            :swiper-config="swiperConfig"
            :locals="locals"
          >
            <template #default="{ item }">
              <div 
                class="negative-info-item"
              >
                <cartListItemImg
                  :item="item"
                  hide-corner
                  hide-stock
                />
                <CartItemPriceSpan
                  class="negative-info-item__price"
                  :item="item"
                  :show-type="'text'"
                  hide-estimated
                />
              </div>
            </template>
          </CartItemSwiper>
        </div>
        <!-- 不可参与优惠券活动的商品 -->
        <div 
          v-if="negativeInfoObj.disableCoupons.length > 0"
          class="cart-item__disable-coupon"
        >
          <div class="negative-swiper-title">
            <Icon
              name="sui_icon_info_16px_1"
              size="16px"
            />
            {{ language.SHEIN_KEY_PC_23723 }}
          </div>
          <CartItemSwiper
            v-if="renderSwiper"
            :items="negativeInfoObj.disableCoupons"
            :swiper-config="swiperConfig"
            :locals="locals"
          >
            <template #default="{ item }">
              <div 
                class="negative-info-item"
              >
                <cartListItemImg
                  :item="item"
                  hide-corner
                  hide-stock
                />
                <CartItemPriceSpan
                  class="negative-info-item__price"
                  :item="item"
                  :show-type="'text'"
                  hide-estimated
                />
              </div>
            </template>
          </CartItemSwiper>
        </div>
        <!-- 不参与包邮活动的商品 -->
        <div 
          v-if="negativeInfoObj.disableFreeShippings.length > 0"
          class="cart-item__disable-free-shipping"
        >
          <div class="negative-swiper-title">
            <Icon
              name="sui_icon_info_16px_1"
              size="16px"
            />
            {{ language.SHEIN_KEY_PC_19503 }}
          </div>
          <CartItemSwiper
            v-if="renderSwiper"
            :items="negativeInfoObj.disableFreeShippings"
            :swiper-config="swiperConfig"
            :locals="locals"
          >
            <template #default="{ item }">
              <div 
                class="negative-info-item"
              >
                <cartListItemImg
                  :item="item"
                  hide-corner
                  hide-stock
                />
                <CartItemPriceSpan
                  class="negative-info-item__price"
                  :item="item"
                  :show-type="'text'"
                  hide-estimated
                />
              </div>
            </template>
          </CartItemSwiper>
        </div>
        <!-- 不可退的商品 -->
        <div 
          v-if="negativeInfoObj.disableReturns.length > 0"
          class="cart-item__disable-return"
        >
          <div class="negative-swiper-title">
            <Icon
              name="sui_icon_info_16px_1"
              size="16px"
            />
            {{ language.SHEIN_KEY_PC_15990 }}
          </div>
          <CartItemSwiper
            v-if="renderSwiper"
            :items="negativeInfoObj.disableReturns"
            :swiper-config="swiperConfig"
            :locals="locals"
          >
            <template #default="{ item }">
              <div 
                class="negative-info-item"
              >
                <cartListItemImg
                  :item="item"
                  hide-corner
                  hide-stock
                />
                <CartItemPriceSpan
                  class="negative-info-item__price"
                  :item="item"
                  :show-type="'text'"
                  hide-estimated
                />
              </div>
            </template>
          </CartItemSwiper>
        </div>
      </div>
    </s-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartItemSwiper from './CartItemSwiper.vue'
import cartListItemImg from '../cartListItemImg.vue'
import CartItemPriceSpan from './CartItemPriceSpan.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon, sui_icon_info_16px_1 } from '@shein-aidc/icon-vue3'

export default {
  name: 'NegativeInfo',
  components: {
    CartItemSwiper,
    cartListItemImg,
    CartItemPriceSpan,
    sui_icon_info_16px_1,
    Icon
  },
  props: {
    mallCartItem: {
      type: Object,
      default() {
        return {}
      }
    },
    mallCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      renderSwiper: true,
      showNegativeInfos: false,
      swiperConfig: {
        slidesPerGroup: 3,
      }
    }
  },
  computed: {
    ...mapState(['locals', 'language', 'soldoutIds', 'ABTParams', 'mallShippingInfo', 'isPlatform', 'cartList']),
    ...mapGetters(['showOBMStore', 'singleMallCode']),
    negativeInfoObj() {
      let negativeInfoObj = {
        disablePoints: [],
        disableCoupons: [],
        disableFreeShippings: [],
        disableReturns: [],
        hasNegativeInfo: false
      }
      // 收集MALL维度的负向信息
      this.cartList?.forEach(item => {
        if(this.mallCode == item.mall_code && this.soldoutIds.indexOf(item.id) == -1) {
          if(item.product.double_status == 0) negativeInfoObj.disablePoints.push(item)
          if(item.return_flag != 0) negativeInfoObj.disableReturns.push(item)
          if(this.isShowNoCouponUse(item) == 'unused_coupon_tip_1') negativeInfoObj.disableCoupons.push(item)
          if(this.showNoFreeShipping(item)) negativeInfoObj.disableFreeShippings.push(item)
        }
      })
      // 收集mall维度的负向信息
      // let listItems = this.mallCartItem?.mall_list
      // Array.isArray(listItems) && listItems.forEach(listItem => {
      //   let promotionGroups = this.showOBMStore ? [listItem] : listItem.store_list
      //   Array.isArray(promotionGroups) && promotionGroups.forEach(promotionGroup => {
      //     Array.isArray(promotionGroup?.list) && promotionGroup.list.forEach(item => {
      //       if(this.soldoutIds.indexOf(item.id) == -1){
      //         if(item.product.double_status == 0) negativeInfoObj.disablePoints.push(item)
      //         if(item.return_flag != 0) negativeInfoObj.disableReturns.push(item)
      //         if(this.isShowNoCouponUse(item) == 'unused_coupon_tip_1') negativeInfoObj.disableCoupons.push(item)
      //         if(this.showNoFreeShipping(item)) negativeInfoObj.disableFreeShippings.push(item)
      //       }
      //     })
      //   })
      // })
      // 更新长度标志
      if(negativeInfoObj.disablePoints.length || negativeInfoObj.disableCoupons.length || negativeInfoObj.disableFreeShippings.length || negativeInfoObj.disableReturns.length) negativeInfoObj.hasNegativeInfo = true
      return negativeInfoObj
    }
  },
  watch: {
    showNegativeInfos(val) {
      if (!val) return
      this.renderSwiper = false
      this.$nextTick(() => {
        this.renderSwiper = true
      })
    }
  },
  methods: {
    handleNegativeInfoShow() {
      this.showNegativeInfos = true
      daEventCenter.triggerNotice({
        daId: '1-7-3-20',
        extraData: {
          mall_code: this.mallCode
        }
      })
    },
    isShowNoCouponUse (item) {
      let flag = 'unused_coupon_tip_0'
      if (item?.store_type != 2) return flag
      if (item?.product?.have_tag == 1) {
        flag = `unused_coupon_tip_${
          this.ABTParams?.['Cartnoteligibleforcoupons']?.param == 'on'
            ? 1
            : 2
        }`
      }
      return flag
    },
    showNoFreeShipping (item) {
      if(this.isPlatform){
        let result = 0
        let platformActivityType =  this.mallShippingInfo[`platform`]?.activity_type || ''
        result = (platformActivityType == 1 && item.free_activity_limit == 1) || (platformActivityType == 2 && item.free_policy_limit == 1) || (platformActivityType == 3 && item.freight_activity_limit == 1)
        if(result){
          return 1
        }
        let shopOrSelfActivityType = this.mallShippingInfo[item.mall_code]?.activity_type || ''
        result = (shopOrSelfActivityType == 1 && item.free_activity_limit == 1) || (shopOrSelfActivityType == 2 && item.free_policy_limit == 1) || (shopOrSelfActivityType == 3 && item.freight_activity_limit == 1)
        return result ? 2 : 0
      }
      let actType = this.mallShippingInfo[this.singleMallCode]?.activity_type || ''
      return (actType == 1 && item.free_activity_limit == 1) || ( actType == 2 && item.free_policy_limit == 1) || (actType == 3 && item.freight_activity_limit == 1) ? 2 : 0
    },
  }
}
</script>

<style lang="less" scoped>
.cart-negative-info {
  margin-top: 1px;
  font-size: 14px;
  padding: 12px 16px 16px;
  background: #fff;
  color: @sui_color_gray_dark3;
  display: flex;
  >*:not(:last-child) {
    margin-right: 4px;
  }
  .negative-view {
    cursor: pointer;
    color: @sui_color_link;
  }
  :deep(.sui-dialog__title) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 16px;
  }
}
.negative-swiper-title {
  font-size: 16px;
  text-align: left;
}
.cart-item__negative-list {
  width: 100%;
  >*:not(:last-child) {
    margin-bottom: 16px;
  }
  .negative-swiper-title {
    margin-bottom: 8px;
  }

}
.negative-info-item {
  width: 120px;
  >*:not(:first-child) {
    margin-top: 5px;
  }
}
.negative-dialog-title {
  font-size: 20px;
  font-weight: 900;
  color: #222;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.negative-info-item__price {
  display: flex;
  flex-wrap: nowrap;
  max-width: 120px;
  margin: 0 auto;
  height: unset;
  :deep(>*) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }
  // 穿透价格单行优先展示
  :deep(>span){
    flex: 0 0 auto;
    max-width: 100%;
  }
  :deep(>del){
    flex: 0 1 auto;
  }
}
</style>
