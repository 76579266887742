// import Container from './index.vue'
import { createApp } from 'vue'
import store from './store'

export default async () => {
  const Container = await import(/* webpackChunkName: "one-click-pay-module" */ './index.vue').catch(() => '')
  if (!Container?.default) return
  const app = createApp(Container?.default)
  app.use(store)
  const root = document.createElement('div')
  root.setAttribute('class', 'oneclick-pay-container')
  document.body.appendChild(root)
  const instance = app.mount(root)

  instance.unmount = () => {
    instance.unmount()
    document.body.removeChild(root)
  }

  return instance
}
