import IncentiveReducePrice from './IncentiveReducePrice.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useIncentiveReducePrice = createHelper('IncentiveReducePrice', getProps)

export {
  IncentiveReducePrice,
  useIncentiveReducePrice,
}
