export function getUnPaidImgs(goodsList = [], limit = 3) {
  let imgs = []
  for(const goods of goodsList) {
    if(goods?.is_prime_goods || goods?.is_xtra_goods) continue
    imgs.push({
      goods_img: goods?.product?.goods_img,
      goods_name: goods?.product?.goods_name
    })
    if(imgs.length >= limit) break
  }
  return imgs
}
