<template>
  <div
    class="store-header-container"
    da-expose-code="cart-shop-name"
    :class="{'not-platform-store': !isPlatform && !isBatchActivePage}"
  >
    <div class="store-header_left">
      <slot></slot>
      <div
        class="store-header-content"
        da-event-expose="1-7-1-18"
        da-event-click="1-7-1-19"
        :data-store-code="storeCode"
        :data-store-tp="storeType == 1 ? 1 : 2"
        :data-preferred-seller="preferredSellerStore == 1 ? 1 : 0"
        @click="goToStore"
      >
        <div class="store-header">
          <preferred-seller-store-icon
            v-if="preferredSellerStore == 1"
            :public-cdn="publicCdn"
          />
          <i
            v-else
            class="sh_pc_sui_icon_store_20px store-icon"
          ></i>
          <span
            class="store-title"
            :class="{'seller-store-title': preferredSellerStore == 1}"
          >{{ store_title }}</span>
          <sui_icon_more_right_14px
            v-if="storeTitle != 'shein' && storeCode != 1"
            :is-rotate="locals.GB_cssRight"
            size="14px"
          />
        </div>
      </div>
    </div>

    <!-- 领取优惠券入口 -->
    <get-coupon
      :store-code="storeCode"
      :mall-code="mallCode"
      :store-type="storeType"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
const daEventExpose = daEventCenter.getExposeInstance()

import getCoupon from '../getCoupon/index.vue'
import preferredSellerStoreIcon from '../preferredSellerStoreIcon.vue'
import { stringifyQueryString } from '@shein/common-function'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'

export default {
  name: 'PlatformStoreHeader',
  components: {
    getCoupon,
    preferredSellerStoreIcon,
    sui_icon_more_right_14px
  },
  props: {
    storeCode: {
      type: [String, Number],
      default: ''
    },
    mallCode: {
      type: [String, Number],
      default: ''
    },
    storeTitle: {
      type: String,
      default: ''
    },
    storeLogo: {
      type: String,
      default: ''
    },
    storeType: {
      type: [String, Number],
      default: ''
    },
    preferredSellerStore: {
      type: [String, Number],
      default: 0
    },
    storeListOrigin: {
      type: Array,
      default() {
        return []
      }
    },
    isBatchActivePage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['storeSelectsInfo', 'locals', 'isPlatform']),
    store_title(){
      if(this.storeTitle == 'shein'){
        return this.locals?.IS_RW ? 'ROMWE' : 'SHEIN'
      }
      return this.storeTitle
    },
    publicCdn() {
      return this.locals?.PUBLIC_CDN
    },
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    goToStore: function (){
      if(this.storeTitle == 'shein' || this.storeCode == 1){
        return
      }

      let url = this.storeSelectsInfo[this.storeCode]
      if(url){
        url = url + (url.indexOf('?') > -1 ? '&' : '?') + this.getStoreQuery() 
        window.open(url, '_blank' )
      }
    },
    getStoreQuery() {
      const goodsIds = []
      const cateIds = []
      if (this.storeListOrigin && this.storeListOrigin.length) {
        this.storeListOrigin.forEach(item => {
          if (item.product) {
            goodsIds.push(item.product.goods_id)
            cateIds.push(item.product.cat_id)
          }
        })
      }
      return stringifyQueryString({
        queryObj: {
          rule_poskey: 'CartShopItemList',
          goods_ids: goodsIds.join(','),
          cate_ids: cateIds.join(','),
        }
      })
    },
    initExpose() {
      daEventExpose.subscribe && daEventExpose.subscribe({
        keycode: 'cart-shop-name`1-7-1-18',
      })
    },
  }
}
</script>

<style lang="less" scoped>
.store-header-container{
  background-color: #FFFFFF;
  line-height: 18px;
  padding: 16px 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
  .store-header_left {
    width: 80%;
    display: flex;
    align-items: center;
  }
  .store-header-content{
    position: relative;
    flex: 1;
  }
  .store-header{
    padding: 0;
    cursor: pointer;
    width: 100%;
    top:0;
    display: flex;
    align-items: center;
    .store-title{
      color: @sui_color_gray_dark1;
      font-size: 16px;
      padding: 1px 4px 2px 0;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      max-width: 90%;
      margin-left: 4px;
      top: 2px;
      position: relative;
    }
    .seller-store-title {
      padding-bottom: 0;
    }
    .store-icon{
      position: relative;
      top: -1px;
    }
    /* stylelint-disable-next-line selector-class-pattern */
    .sh_pc_sui_icon_store_20px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS41IDExTDIuODA3NzMgM0gxNy4xOTIzTDE4LjUgMTFIMTdWMThIM1YxMUgxLjVaTTMuMjY1MTEgOS41SDdWNC41SDQuMDgyNDRMMy4yNjUxMSA5LjVaTTguNSA5LjVIMTEuNVY0LjVIOC41VjkuNVpNMTMgOS41SDE2LjczNDlMMTUuOTE3NiA0LjVIMTNWOS41Wk00LjUgMTFWMTYuNUgxNS41VjExSDQuNVoiIGZpbGw9ImJsYWNrIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
    }
  }
  &.not-platform-store{
    margin-top: 5px;
  }
}
</style>
