import { setLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { setUserInfo } from 'public/src/pages/common/userInfo'
import { getSatisfyFreeShipping } from './getSatisfyFreeShipping'

const cartLoginView = {
  sucCb: null,
  beforeCartSumQuantity: 0,
  extraData: {},
  abt: {},
  login: function ({ type = '', cb = null, beforeCartSumQuantity = 0, abt = {}, extraData = {} }) {
    let loginType = ''
    if (type == 'checkout') {
      loginType = 'checkout'
    } else if (type === 'saveforlater') {
      loginType = 'wishlist'
    } else {
      loginType = 'other'
    }
    this.sucCb = cb
    this.beforeCartSumQuantity = beforeCartSumQuantity
    this.extraData = extraData
    this.abt = abt

    const { cartInfo, cartList } = window.cartVue.$store.state
    let promotion_price
    if (Number(cartInfo?.total_discount_price?.amount) > 0) {
      promotion_price = cartInfo.total_discount_price.amountWithSymbol
    }
    let retainData = {
      promotion_price,
      free_shipping: cartList?.length && getSatisfyFreeShipping()
    }
    
    SHEIN_LOGIN.show({
      cb: this.loginModalSucCb.bind(this),
      from: loginType,
      bi: loginType,
      retainData,
    })
  },
  loginModalSucCb: async function ({ info }) {
    // let processResult = null
    setUserInfo({ member_id: info?.member?.member_id || '' })
    window.cartCheckLogin = new Promise((resolve) => {
      resolve({ username: info?.member?.member_id || false })
    })
    const res = await this.updateCart()
    const result = {
      equalCart: false
    }
    if (res.code == 0) {
      result.equalCart = this.beforeCartSumQuantity === res.info?.cartSumQuantity
      if ((typeof SaPageInfo !== 'undefined') && (typeof SaPageInfo.page_param == 'object') && !result.equalCart) {
        SaPageInfo.page_param.is_combine = 0
        setLocalStorage({ key: 'is_combine', value: '0', expire: 1000 * 60 })
      }
    } else {
      console.log('login failed')
    }
    this.sucCb && this.sucCb(result, res)
  },
  updateCart: function () {
    return schttp({
      url: '/api/cart/checkcart/get'
    })
  }
}

export default cartLoginView
