<template>
  <div
    ref="tempRef"
    class="mini-carts__wrap"
  >
    <!-- 商品行内容 -->
    <div class="mini-carts__content">
      <!-- 主图、标签、腰带 -->
      <div class="mini-carts__left">
        <GoodsImg
          v-if="goodsImgData.isShow"
          v-bind="goodsImgData.attrs"
        >
          <template #left>
            <GoodsDiscountFlag
              v-if="goodsDiscountFlagData.isShow"
              v-bind="goodsDiscountFlagData.attrs"
            />
          </template>
          <template #right>
            <GoodsBlackFridayFlag
              v-if="goodsBlackFridayFlagData.isShow"
              v-bind="goodsBlackFridayFlagData.attrs"
            />
            <GoodsSeriesFlag
              v-if="goodsSeriesFlagData.isShow && !goodsBlackFridayFlagData.attrs.isShow"
              v-bind="goodsSeriesFlagData.attrs"
            />
          </template>
          <template #bottom>
            <GoodsBelt
              v-if="goodsBeltData.isShow"
              v-bind="goodsBeltData.attrs"
            />
          </template>
        </GoodsImg>
      </div>
      <div class="mini-carts__right">
        <!-- 标题 -->
        <div class="mini-carts__right-top">
          <GoodsTitle
            v-if="goodsTitleData.isShow"
            v-bind="goodsTitleData.attrs"
          >
            <template #left>
              <GoodsTitleIcon
                v-if="goodsTitleIconData.isShow"
                v-bind="goodsTitleIconData.attrs"
              />
            </template>
          </GoodsTitle>
          <GoodsStore
            v-if="goodsStoreData.isShow"
            class="mini-carts__store"
            v-bind="goodsStoreData.attrs"
          />
        </div>
        <div class="mini-carts__right-middle">
          <!-- 尺码、商品数量 -->
          <div class="mini-carts__info">
            <GoodsSaleAttr
              v-if="goodsSaleAttrData.isShow"
              class="mini-carts__sale-attr"
              v-bind="goodsSaleAttrData.attrs"
            />
            <GoodsQty
              v-if="goodsQtyData.isShow && goodsQtyData.position === 'up'"
              class="mini-carts__goods-qty"
              v-bind="goodsQtyData.attrs"
            />
          </div>
          <!-- 加车组件 -->
          <AddMore
            v-if="addMoreData.isShow"
            class="mini-carts__add-more"
            v-bind="addMoreData.attrs"
          />
          <!-- 用户行为标签 -->
          <ClientOnly>
            <BehaviorLabel
              v-if="behaviorLabelData.isShow"
              class="mini-carts__behavior-label"
              v-bind="behaviorLabelData.attrs"
            />
          </ClientOnly>
          <!-- 活动标签 -->
          <div
            v-if="quickShipTagData.attrs.isShow || freeShippingTagData.attrs.isShow || evoluTagData.attrs.isShow || voucherTagData.attrs.isShow || promotionTagData.attrs.isShow"
            class="mini-carts__promotion"
          >
            <QuickShipTag
              v-if="quickShipTagData.isShow"
              class="mini-carts__promotion-item"
              v-bind="quickShipTagData.attrs"
            />
            <FreeShippingTag
              v-if="freeShippingTagData.isShow"
              class="mini-carts__promotion-item"
              v-bind="freeShippingTagData.attrs"
            />
            <EvoluTag
              v-if="evoluTagData.isShow"
              class="mini-carts__promotion-item"
              v-bind="evoluTagData.attrs"
            />
            <VoucherTag
              v-if="voucherTagData.isShow"
              class="mini-carts__promotion-item"
              v-bind="voucherTagData.attrs"
            />
            <PromotionTag
              v-if="promotionTagData.isShow"
              class="mini-carts__promotion-item"
              v-bind="promotionTagData.attrs"
            />
          </div>
          <!-- 提示信息 -->
          <ReducePriceHint
            v-if="reducePriceHintData.isShow"
            class="mini-carts__reduce-price-hint"
            v-bind="reducePriceHintData.attrs"
          />
          <GoodsAlert
            v-if="goodsAlertData.isShow"
            class="mini-carts__newbie-price-hint"
            v-bind="goodsAlertData.attrs"
          />
        </div>
        <div class="mini-carts__right-bottom">
          <!-- 价格、数量、删除 -->
          <div class="mini-carts__right-bottom-above">
            <div class="mini-carts__price">
              <GoodsPrice
                v-if="goodsPriceData.isShow"
                v-bind="goodsPriceData.attrs"
              />
            </div>
            <div class="mini-carts__operation">
              <GoodsQty
                v-if="goodsQtyData.isShow && goodsQtyData.position !== 'down'"
                class="mini-carts__goods-qty"
                v-bind="goodsQtyData.attrs"
              />
              <GoodsDeleteButtonV1
                v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v1'"
                class="mini-carts__delete"
                v-bind="goodsDeleteButtonData.attrs"
              />
              <GoodsDeleteButtonV2
                v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v2'"
                class="mini-carts__delete"
                v-bind="goodsDeleteButtonData.attrs"
              />
            </div>
          </div>
          <!-- 到手价、降价利诱、售罄利诱 -->
          <div class="mini-carts__right-bottom-below">
            <EstimatedPrice
              v-if="estimatedPriceData.isShow"
              class="main-carts__estimated-price"
              v-bind="estimatedPriceData.attrs"
            />
            <IncentiveReducePrice
              v-if="incentiveReducePriceData.isShow"
              class="mini-carts__incentive-item"
              v-bind="incentiveReducePriceData.attrs"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 负向信息 -->
    <div class="mini-carts__other">
      <GoodsNegativeInfo
        v-if="goodsNegativeInfoData.isShow"
        v-bind="goodsNegativeInfoData.attrs"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

import { ClientOnly } from '@sheinfe/vue-client-only'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goodsImg'
import { GoodsTitle, useGoodsTitle } from '../cell/goodsTitle'
import { GoodsStore, useGoodsStore } from '../cell/goodsStore'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goodsSeriesFlag'
import { GoodsBlackFridayFlag, useGoodsBlackFridayFlag } from '../cell/goodsBlackFridayFlag'
import { GoodsDeleteButtonV1, GoodsDeleteButtonV2, useGoodsDeleteButton } from '../cell/goodsDeleteButton'
import { GoodsDiscountFlag, useGoodsDiscountFlag } from '../cell/goodsDiscountFlag'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goodsSaleAttr'
import { GoodsAlert, useGoodsAlert } from '../cell/goodsAlert'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behaviorLabel'
import { QuickShipTag, useQuickShipTag } from '../cell/goodsTags/quickShipTag'
import { FreeShippingTag, useFreeShippingTag } from '../cell/goodsTags/freeShippingTag'
import { EvoluTag, useEvoluTag } from '../cell/goodsTags/evoluTag'
import { VoucherTag, useVoucherTag } from '../cell/goodsTags/voucherTag'
import { PromotionTag, usePromotionTag } from '../cell/goodsTags/promotionTag'
import { ReducePriceHint, useReducePriceHint } from '../cell/reducePriceHint'
import { GoodsQty, useGoodsGoodsQty } from '../cell/goodsQty'
import { GoodsBelt, useGoodsBelt } from '../cell/goodsBelt'
import { GoodsPrice, useGoodsPrice } from '../cell/goodsPrice'
import { EstimatedPrice, useEstimatedPrice } from '../cell/estimatedPrice'
import { GoodsTitleIcon, useGoodsTitleIcon } from '../cell/goodsTitleIcon'
import { AddMore, useAddMore } from '../cell/addMore'
import { IncentiveReducePrice, useIncentiveReducePrice } from '../cell/incentiveReducePrice'
import { GoodsNegativeInfo, useGoodsNegativeInfo } from '../cell/goodsNegativeInfo'

/**
 * 迷你车商品行模板
 */

// template hooks
import { templateProps, useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps(templateProps)

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsTitleData,
    goodsStoreData,
    goodsSeriesFlagData,
    goodsBlackFridayFlagData,
    goodsDeleteButtonData,
    goodsDiscountFlagData,
    goodsSaleAttrData,
    goodsAlertData,
    behaviorLabelData,
    quickShipTagData,
    freeShippingTagData,
    evoluTagData,
    voucherTagData,
    promotionTagData,
    reducePriceHintData,
    goodsQtyData,
    goodsBeltData,
    estimatedPriceData,
    goodsPriceData,
    goodsTitleIconData,
    addMoreData,
    incentiveReducePriceData,
    goodsNegativeInfoData,
  ],

  // methods
  registerExpose,
} = useTemplate(
  props,
  // Helper functions used by the current template
  [
    useGoodsImg,
    useGoodsTitle,
    useGoodsStore,
    useGoodsSeriesFlag,
    useGoodsBlackFridayFlag,
    useGoodsDeleteButton,
    useGoodsDiscountFlag,
    useGoodsSaleAttr,
    useGoodsAlert,
    useBehaviorLabel,
    useQuickShipTag,
    useFreeShippingTag,
    useEvoluTag,
    useVoucherTag,
    usePromotionTag,
    useReducePriceHint,
    useGoodsGoodsQty,
    useGoodsBelt,
    useEstimatedPrice,
    useGoodsPrice,
    useGoodsTitleIcon,
    useAddMore,
    useIncentiveReducePrice,
    useGoodsNegativeInfo
  ]
)

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMounted(handleExpose)
</script>

<style lang="less" scoped>
.mini-carts {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    text-align: left;
  }
  &__content {
    display: flex;
  }
  &__left {
    margin-right: 10px;
    width: 90px;
    height: 90px;
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 90px;
    width: 0;
  }
  &__right-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
  }
  &__right-top {
    display: flex;
    flex-direction: column;
  }
  &__right-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  &__right-bottom-above {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__right-bottom-below {
    display: flex;
    align-items: center;
  }
  &__store {
    margin-top: 6px;
  }
  &__info {
    display: flex;
    align-items: center;
    margin-top: 6px;
    justify-content: space-between;
  }
  &__tips {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__sale-attr {
    padding-right: 6px;
  }
  &__goods-qty {
    flex-shrink: 0;
  }
  &__add-more {
    margin-top: 6px;
    width: fit-content
  }
  &__behavior-label {
    margin-top: 6px;
  }
  &__price {
    flex: 1;
    overflow: hidden;
  }
  &__operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    :nth-child(1) {
      margin-left: 6px;
    }
  }
  &__promotion {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 6px;
  }
}
</style>
