import { reactive, ref } from 'vue'
import Methods from '../methods'
import { PPGA_CHECK_TYPE } from '../../../common/constants'
const paypalGaVault = reactive({
  valid: false,
  visibleArrow: false,
  radioType: PPGA_CHECK_TYPE.CHECKED,
  type: PPGA_CHECK_TYPE.CHECKED,
  express: false, // 是否使用快捷选中方式 - 未签约，且允许选择是否记住卡号
})
const ppgvAccountInfo = ref({})

export default () => {
  const handleInitPaypalVaulting = ({ paymentMethods, abtInfo }) => {
    if (!paymentMethods.value?.length) return
    ppgvAccountInfo.value = Methods.getPpgvAccountInfo({ paymentMethods: paymentMethods.value }) || {}
    const vaultingConfig = Methods.initPaypalVaultingConfig({ abtInfo: abtInfo.value, ppgvAccountInfo: ppgvAccountInfo.value })
    Object.assign(paypalGaVault, vaultingConfig)
  }

  const resetState = () => {
    paypalGaVault.valid = false
    paypalGaVault.visibleArrow = false
    paypalGaVault.radioType = PPGA_CHECK_TYPE.CHECKED
    paypalGaVault.type = PPGA_CHECK_TYPE.CHECKED
    ppgvAccountInfo.value = {}
  }

  return {
    paypalGaVault,
    ppgvAccountInfo,
    handleInitPaypalVaulting,
    resetState
  }
}
