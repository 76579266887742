
import { computed, ref } from 'vue'
import { parseQueryString } from '@shein/common-function'
import Methods from '../methods'
import { getInstallmentInfo } from '../fetcher'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import useInitPaypalVaulting from './useInitPaypalVaulting'
import useInitRpcToken from './useInitRpcToken'

const { rpcCardSelect, rpcTokenInfo } = useInitRpcToken()
const { paypalGaVault, ppgvAccountInfo } = useInitPaypalVaulting()

const cacheMap = new Map()

export default ({
  abtInfo,
  paymentMethods,
  payParams,
  calculateMallInfo
} = {}) => {
  let _requesting = false

  const payInlineAbtInfo = computed(() => {
    return Methods.getPayInlineAbtInfo({ abtInfo: abtInfo.value })
  })
  const needPayInlineMethods = computed(() => {
    return Methods.getNeedPayInlineMethods({
      payInlineAbtInfo: payInlineAbtInfo.value,
      paymentMethods: paymentMethods.value
    })
  })

  const installmentInfo = ref({})

  async function fetchInstallmentInfo (data = {}) {
    data = Object.assign({}, {
      country: payParams?.value?.country_code,
      amount: calculateMallInfo?.value?.grandTotalPrice?.amount,
      currency: payParams?.value?.currency
    }, data)
    if (_requesting) return
    _requesting = true
    const { country, amount, currency } = data || {}
    const CACHE_KEY = `ocp_${country}_${amount}_${currency}`
    if (cacheMap.has(CACHE_KEY)) {
      _requesting = false
      installmentInfo.value = cacheMap.get(CACHE_KEY)
      return installmentInfo.value
    }
    const res = await getInstallmentInfo({
      country,
      amount,
      currency
    })
    // eslint-disable-next-line require-atomic-updates
    _requesting = false
    if (res?.info?.front_show_list?.length) {
      const front_list_items = {}
      res.info.front_show_list.forEach(item => {
        front_list_items[item.payment_code] = item
      })
      res.info.front_list_items = front_list_items
      installmentInfo.value = res.info || {}
    }
    if (res?.code == 0 && res?.info) {
      cacheMap.set(CACHE_KEY, res?.info || {})
    }
    return installmentInfo.value
  }

  // 一键购埋点 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1171716937
  const ocpAnalysis = async ({
    other = {},
    daId = '',
    detailPopupOptions = {}
  }) => {
    let extraData = {
      default_payment_code: detailPopupOptions?.defaultPaymentInfo,
      is_signed: '-',
      is_token: '-',
      activity_from: '',
      location: detailPopupOptions.location || 'popup', //默认弹窗来源
      ...other
    }
    if (detailPopupOptions?.defaultPaymentInfo === 'routepay-card') {
      extraData.is_token = rpcCardSelect?.type == '1' && rpcTokenInfo.value?.id ? 1 : 0
    }
    if (detailPopupOptions?.defaultPaymentInfo === 'PayPal-GApaypal') {
      extraData.is_signed = paypalGaVault.type == '1' && !!ppgvAccountInfo.value?.sign_up_email ? 1 : 0
    }
    const { src_module } = parseQueryString(location.search || '')
    if(['page_payment_successful', 'page_order_detail'].includes(SaPageInfo?.page_name) 
      || ( SaPageInfo?.page_name === 'page_goods_detail' && ['page_payment_successful_page', 'page_order_detail_page'].includes(src_module))){
      extraData.activity_from = 'one_tap_pay'
    }
    daEventCenter.triggerNotice({ daId, extraData })
  }

  return {
    payInlineAbtInfo,
    needPayInlineMethods,
    installmentInfo,
    fetchInstallmentInfo,
    ocpAnalysis
  }
}
